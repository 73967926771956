import React from 'react'
import { useNavigate } from 'react-router-dom';

const Topbar = () => {   
  let navigate = useNavigate();
  const onLogout = () => {
    localStorage.removeItem('authuser'); 
    navigate('/')   
  }

  return (
    <div className="relative bg-gray-50">     
      <div className="max-w mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-2 md:justify-start md:space-x-10">
          <div className="flex justify-start items-center lg:w-0 lg:flex-1"> 
            <img alt="Logo" src="./img/logo-demo7.svg" className="h-12 md:h-16 lg:h-16"/> 
            &nbsp;&nbsp;      
            <h2 className="text-2xl font-bold">Marketing Dashboard</h2>             
          </div>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">  
            <button type="submit" id="kt_sign_in_submit" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
            onClick={onLogout}
            >
            <span className="text-white font-sm">Logout</span>
            </button>       
          </div>
        </div>
      </div>      
    </div>
  )
}

export default Topbar