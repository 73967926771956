import React, {useEffect, useState} from 'react'
import Layout from '../Layout'
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid'
import { SearchIcon } from '@heroicons/react/outline'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const AdFormList = ({}) => {     
    const token =JSON.parse(localStorage.getItem("authuser")).access_token
    const headers = { 
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`,
    };
    const url = '/adformlist';

    const [searchParams, setSearchParams] = useSearchParams(); 

    const [adList, setAdList] = useState([])
    const [adListAll, setAdListAll] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [adId, setAdId] = useState('');
    const [mypage, setMyPage] = useState(1);
    const [resPerPage, setResPerPage] = useState(0)
    const [filCount, setFilCount] = useState(0)
    const [totpages,setTotpages]=useState(0)

    const [flSearch, setFlSearch] = useState('');
    const [flStock, setFlStock] = useState('');
    const [flCar, setFlCar] = useState('');
    const [flCarPrice, setFlCarPrice] = useState('');
    const [flCarYear, setFlCarYear] = useState('');
    const [pgNext, setPgNext] = useState(0)
    const [date, setDate] = useState('');


    const handleSearch = e => {
        e.preventDefault()
        if(e.target.name === 'search') {
            setFlSearch(e.target.value)
            setFlCar('')
            setFlStock('')
            setDate('')  
        }else if (e.target.name === 'carstockno') {
            setFlStock(e.target.value)
            setFlSearch('')
            setFlCar('')
            setDate('')  
        }else if (e.target.name === 'carname') {
            setFlCar(e.target.value)
            setFlSearch('')
            setFlStock('')
            setDate('')  
        }
        else if (e.target.name === 'createdate') {
            setDate(e.target.value)            
            setFlSearch('')
            setFlCar('')
            setFlStock('')
        }
        // setSearchField(e.target.value);        
    };

    useEffect( async() => {
        // Ads - Filtered and Pagination
        try {
            let link = `${serverAPI}/adGenFilter?page=1`            
            if (flStock) link = link.concat(`&carstockno=${flStock}`)
            if (flCar) link = link.concat(`&carname=${flCar}`)
            if (flCarPrice) link = link.concat(`&carprice=${flCarPrice}`)
            if (flCarYear) link = link.concat(`&caryear=${flCarYear}`)           
            if (date) link = link.concat(`&createdate=${date}`)
            const res = await axios.get(link, {headers})
            setAdList(res.data.findAdGens)
            setFilCount(res.data.filteredAdCount)
            setResPerPage(res.data.resPerPage)
            setTotpages(Number(Math.ceil(res.data.filteredAdCount/res.data.resPerPage)))
        } catch(err) {
        // console.log(err)
        } 
    }, [flStock, flCar, date])

    const handleClear = (e) => { 
        e.preventDefault()   
        window.location.href = url
    }

    const handleModal= (e, id)=>{
        e.preventDefault()          
        setAdId(id)
        setShowModal(true)
    }

    const submitDel = async (e) => {       
        e.preventDefault()
        try{
            const res = await axios.delete(`${serverAPI}/adGen/${adId}`, {headers})
            .then(ad => {                      
                window.location.href=url
            }).catch((err) => {
                toast.error("Ad not removed !")                                  
            }) 
        }catch(err){
            // console.log(err)
            toast.error("Server error. Try Again !")
        }
        setShowModal(false)      
    }

    useEffect( async() => {
        // List all Ads
        try {
            const res = await axios.get(`${serverAPI}/adGen`,{headers})
            setAdListAll(res.data)        
        } catch(err) {
        //    console.log(err)
        } 

        // Set Page
        let page
        if (searchParams.has("page")) {
            page = Number(searchParams.get("page"))            
        } else {
            page = Number(mypage)
        }
        setMyPage(page)

        // Ads - Filtered and Pagination
        try {
            let link = `${serverAPI}/adGenFilter?page=${page}`            
            if (flStock) link = link.concat(`&carstockno=${flStock}`)
            if (flCar) link = link.concat(`&carname=${flCar}`)
            if (flCarPrice) link = link.concat(`&carprice=${flCarPrice}`)
            if (flCarYear) link = link.concat(`&caryear=${flCarYear}`)           
            if (date) link = link.concat(`&createdate=${date}`)  
            const res = await axios.get(link, {headers})
            setAdList(res.data.findAdGens)
            setFilCount(res.data.filteredAdCount)
            setResPerPage(res.data.resPerPage)
            setTotpages(Number(Math.ceil(res.data.filteredAdCount/res.data.resPerPage)))
        } catch(err) {
        //    console.log(err)
        } 
    }, [])

    // useEffect( async() => {       
    //     if (searchParams.has("page")) {
    //         let page = Number(searchParams.get("page"))  
    //         console.log('change page count', page)   
    //         setMyPage(page)       
    //     }        
    // }, [mypage])

    const handlePagination = (pageNumber) => {
        if(flStock!==undefined){
            window.location.href = `adformlist?carstockno=${flStock}&page=${pageNumber}`
        }
        else if(flCar!==undefined){
            window.location.href = `adformlist?carname=${flCar}&page=${pageNumber}`
        }
        else if(date!==undefined){
            window.location.href = `adformlist?createdate=${date}&page=${pageNumber}`
        }
        else{
            window.location.href = `adformlist?page=${pageNumber}`  
        }   
        // window.location.href = `adformlist?page=${pageNumber}`       
    }

    const submitSearch = async (e) => {
        e.preventDefault() 
        if(!flSearch) {
            toast.error('Click on Clear button and try again !')
        }
        setFlCar('')
        setFlStock('')
        try{
            let link = `${serverAPI}/adGenFilter?page=1`            
            if (flSearch) link = link.concat(`&search=${flSearch}`)
            const res = await axios.get(link, {headers})
            setAdList(res.data.findAdGens)
            setFilCount(res.data.filteredAdCount)
            setResPerPage(res.data.resPerPage)
            setTotpages(Number(Math.ceil(res.data.filteredAdCount/res.data.resPerPage)))
        }catch(err){
            // console.log(err)
        }
    }

    return (
        <>    
        <Layout>    
            <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Ad Generations - All Ads</h1>              
                    </div>                             
                   {/* ---- Content Start ---- */}
                    {/* Search Start */}
                    <div className="inline-block min-w-full px-6 py-1 overflow-hidden bg-gray-50 align-middle border-b border-gray-200 shadow sm:rounded-lg">                        
                        <form>
                        <div className="py-3 sm:flex-1 sm:flex sm:items-center sm:justify-between">                              
                            <div className="w-full grid grid-cols-4 gap-3"> 
                                <div className="text-sm leading-5 text-gray-500 justify-between">   
                                    <input  
                                    className="w-full mt-1 bg-gray-200 border-gray-200 rounded py-3 px-2 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:[#F25757] focus:ring-[#F25757] focus:ring-1 " 
                                    type="text" name="search" autoComplete="on"
                                    placeholder='Stock No / Car Name / Year / Price'
                                    onChange={e => setFlSearch(e.target.value)}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                </div>
                                <div className="text-sm leading-5 text-gray-500 justify-left"> 
                                    <button type="submit" id="kt_search" 
                                    className="py-2 px-2 border text-sm font-medium rounded-md hover:bg-gray-200" 
                                    //  className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md hover:bg-gray-200" 
                                    onClick={e=>submitSearch(e)}>
                                    <SearchIcon className="w-5 h-5" />
                                    </button> 
                                </div>
                                <div className="text-sm leading-5 text-gray-500 justify-left"> 
                                    <button type="button" id="kt_clear" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                    onClick={e => handleClear(e)}
                                    >
                                    <span className="text-white font-sm">Clear</span>
                                    </button>    
                                </div>
                            </div>     
                        </div>
                        </form>
                        <div className="hidden py-3 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="w-full grid grid-cols-3 gap-4">
                                <div className="text-sm leading-5 text-gray-500"> 
                                    <label htmlFor="carstockno" className="text-sm font-semibold">Stock No: </label>
                                    <select
                                        className="bg-gray-200  border-gray-200 rounded py-2 px-5 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:[#F25757] focus:ring-[#F25757] focus:ring-1 " 
                                        id="carstockno"
                                        name="carstockno"
                                        autoComplete="carstockno"
                                        onChange={handleSearch}                                    
                                    >                                
                                    <option selected={flStock} defaultValue={flStock} >{flStock}</option>
                                        {adListAll && [...adListAll].slice(0,30).map((x, y) =>  
                                            <option key={y}>{x.carstockno}</option> )
                                        }
                                    </select>
                                </div>
                                <div className="text-sm leading-5 text-gray-500"> 
                                    <label htmlFor="stockno" className="text-sm font-semibold">Car Name: </label>  
                                    <select
                                        className="bg-gray-200  border-gray-200 rounded py-2 px-5 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:[#F25757] focus:ring-[#F25757] focus:ring-1 " 
                                        id="carname"
                                        name="carname"
                                        autoComplete="carname"
                                        onChange={handleSearch}                                    
                                    >
                                    <option selected={flCar} defaultValue={flCar} >{flCar}</option>                                        
                                        {adListAll && [...adListAll].slice(0,30).map((x, y) =>  
                                            <option key={y}>{x.carname}</option> )
                                        }
                                    </select>
                                </div>
                                <div className="text-sm leading-5 text-gray-500"> 
                                    <label htmlFor="stockno" className="text-sm font-semibold">Ad Created Date: </label> 
                                    <input type="date" 
                                    name="createdate"
                                    className="bg-gray-200  border-gray-200 rounded py-2 px-5 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:[#F25757] focus:ring-[#F25757] focus:ring-1 " 
                                    placeholder="Date"  
                                    value={date}
                                    onChange={handleSearch} 
                                    />
                                </div>                         
                            </div>
                        </div>
                    </div>
                    {/* Search End */}
                    <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">                                   
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                <th
                                        className="px-6 py-3 text-sm font-semibold leading-4 text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                        View</th>   
                                    <th
                                        className="px-6 py-3 text-sm font-semibold leading-4 text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                        Stock No
                                        </th>
                                    <th
                                        className="px-6 py-3 text-sm font-semibold leading-4 text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                        Car Name</th>
                                    <th
                                        className="px-6 py-3 text-sm font-semibold leading-4 text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                        Car Year</th>
                                    <th
                                        className="px-6 py-3 text-sm font-semibold leading-4 text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                        Car Price (AED)</th>
                                    <th
                                        className="px-6 py-3 text-sm font-semibold leading-4 text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                        Ad Created Date</th>   
                                    <th
                                        className="w-70 px-6 py-3 text-sm font-semibold leading-4 text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                        Edit Post</th>           
                                    <th
                                        className="px-6 py-3 text-sm font-semibold leading-4 text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                        Delete</th>
                                </tr>
                            </thead>

                            <tbody className="bg-white">                                   
                                {adList && [...adList].map((item, index) => (
                                // {filteredAds && [...filteredAds].map((item, index) => (
                                    <tr key={index}> 
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> 
                                        <Link to={`/adformlisttodayright?id=${item._id}&st=${item.carstockno}&car=${item.carname}&price=${item.carprice}`}>  
                                        <svg className="svg-icon w-7 h-7 text-green-400" viewBox="0 0 20 20" stroke="currentColor" fill="none">
                                            <path d="M10,6.978c-1.666,0-3.022,1.356-3.022,3.022S8.334,13.022,10,13.022s3.022-1.356,3.022-3.022S11.666,6.978,10,6.978M10,12.267c-1.25,0-2.267-1.017-2.267-2.267c0-1.25,1.016-2.267,2.267-2.267c1.251,0,2.267,1.016,2.267,2.267C12.267,11.25,11.251,12.267,10,12.267 M18.391,9.733l-1.624-1.639C14.966,6.279,12.563,5.278,10,5.278S5.034,6.279,3.234,8.094L1.609,9.733c-0.146,0.147-0.146,0.386,0,0.533l1.625,1.639c1.8,1.815,4.203,2.816,6.766,2.816s4.966-1.001,6.767-2.816l1.624-1.639C18.536,10.119,18.536,9.881,18.391,9.733 M16.229,11.373c-1.656,1.672-3.868,2.594-6.229,2.594s-4.573-0.922-6.23-2.594L2.41,10l1.36-1.374C5.427,6.955,7.639,6.033,10,6.033s4.573,0.922,6.229,2.593L17.59,10L16.229,11.373z"></path>
                                        </svg>
                                        </Link>
                                        </div>
                                    </td> 
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {item.carstockno}</div>
                                    </td> 
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {item.carname}</div>
                                    </td>  
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {item.caryear}</div>
                                    </td>  
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {item.carprice}</div>
                                    </td>   
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {moment(item.createdate).format('DD MMM YYYY')}</div>
                                    </td>                                 
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">                                                                  
                                        <Link to={`/adformedittoday?id=${item._id}&st=${item.carstockno}&car=${item.carname}`}>  
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-blue-400"
                                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                        </Link>
                                    </td>                                  
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> 
                                        <Link to={''} onClick={(e) => handleModal(e, item._id)}>  
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-red-400"
                                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                        </Link>                                       
                                        </div>
                                    </td>                                      
                                    </tr>
                                ))} 
                            </tbody>
                        </table>
                    </div>
                    {/* Pagination Start */}
                    {resPerPage < filCount &&
                    <div className="inline-block min-w-full px-6 py-1 overflow-hidden bg-gray-50 align-middle border-b border-gray-200 shadow sm:rounded-lg">
                        <div className="flex-1 flex justify-between sm:hidden">
                            <button color="primary" 
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            onClick={() => handlePagination(mypage-1)}
                            disabled={mypage <= 1}
                            >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                            <button color="primary"                                
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            onClick={() => handlePagination(mypage+1)}  disabled={mypage >=totpages }>
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </button>  
                        </div>
                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="text-sm leading-5 text-gray-500">  Showing <span className="font-medium">1</span> to <span className="font-medium">{resPerPage}</span> of
                                <span className="font-medium"> {filCount} </span> results</div>                            
                            <div>
                            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                <button color="primary" 
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                onClick={() => handlePagination(1)}
                                >
                                <span className="sr-only">First</span>
                                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                                <button color="primary" 
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                onClick={() => handlePagination(mypage-1)}
                                disabled={mypage <= 1}
                                >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                                <button color="primary"                                 
                                className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                onClick={() => handlePagination(mypage)}>
                                {mypage}
                                </button>
                                <button color="primary"                                
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                onClick={() => handlePagination(mypage+1)}  disabled={mypage >=totpages }                            
                                >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                </button> 
                                <button color="primary" 
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                onClick={() => handlePagination(totpages)}
                                >
                                <span className="sr-only">Last</span>
                                <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                                </button>              
                            </nav>
                            </div>
                        </div>
                    </div>
                    }
                    {/* {resPerPage < filCount &&
                        <div className="d-flex justify-content-center mt-5">
                        <button color="primary" className="btn btn-info btn-sm"
                        onClick={() => handlePagination(mypage-1)}
                        disabled={mypage <= 1}
                        >
                        PREV
                        </button>
                        &nbsp;
                        <button color="primary" className="btn btn-info btn-sm" onClick={() => handlePagination(mypage+1)}  disabled={mypage >=totpages }>
                        NEXT
                        </button>
                        &nbsp;
                        <button color="primary" className="btn btn-info btn-sm" onClick={() => handlePagination(1)}>
                        First Page
                        </button>

                        </div>
                    } */}
                    {/* Pagination End */}

                {/* ****** Modal Start ****** */}
                {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            {/* <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            </div> */}
                            {/*body*/}
                            <div className="relative p-8 flex-auto">
                            <p className="my-4 text-blueGray-500 text-lg leading-relaxed">                 
                            Are you sure you want to delete the Ad? 
                            </p>  
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                            <button
                                className="bg-green-600 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowModal(false)}
                            >
                            No
                            </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => submitDel(e)}
                            >
                            Yes
                            {/* {btnLoading && (
                                    <svg role="status" className="inline mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                )} */}
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}  
                {/* ****** Modal End ****** */}
                   {/* ---- Content End ---- */}
                </div>
            </div>    
        </Layout>
        </>
    );
}
    
export default AdFormList