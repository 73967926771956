import React, {useEffect, useState} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../Layout'
import AdSocialAll from './formtemplates/AdSocialAll';
import { adSocial } from '../../utils/envConfig';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const AdListTodayRight = ({}) => { 
    let navigate = useNavigate();

    const [adList, setAdList] = useState('')
    const [carstockno, setCarStockno] = useState('')
    const [carname, setCarname] = useState('')
    const [price, setPrice] = useState('')
    const [load, setLoad] = useState(false)    
    const [searchParams, setSearchParams] = useSearchParams();  

    useEffect( async() => {
        if (searchParams.has("id")) {
            const adID = searchParams.get("id"); 
            setAdList(adID)
            setLoad(true)
        }

        if (searchParams.has("st")) {
            const stno = searchParams.get("st"); 
            setCarStockno(stno)
        }

        if (searchParams.has("car")) {
            const cn = searchParams.get("car"); 
            setCarname(cn)
        }
        if (searchParams.has("price")) {
            const cn = searchParams.get("price").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setPrice(cn)
        }
    }, [])

    return (
        <>             
        <Layout>    
            <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Ad Generations </h1>              
                    </div>                  
                    {/* ---- Content Start ---- */}     
                    {load ?              
                    <div >
                        {/* ---- Left Content Start ---- */}     
                        <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <table className="min-w-full">
                                <thead>
                                    <tr>
                                        <th
                                            className=" px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                            <b>Stock No:</b> {carstockno} </th>
                                        <th
                                            className=" px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                            <b>Car Name:</b> {carname} </th> 
                                            <th
                                            className=" px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                            <b>Price:</b> <p className='text-black font-medium'>AED {price}</p> </th>             
                                        <th
                                            className=" px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                            <button type="button" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                                onClick={() => navigate(-1)  }
                                                >
                                                <span className="text-white font-sm">Go Back</span>
                                            </button>
                                        </th>  
                                        <th
                                            className=" px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                            <Link to={`/adformedittoday?id=${adList}&st=${carstockno}&car=${carname}`}>  
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-blue-400"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                </svg>
                                                <span className="text-gray-500  font-sm">Edit</span>
                                            </Link>
                                        </th>            
                                    </tr>
                                </thead>                             
                            </table>
                                                
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingOne">
                                <button className="
                                    accordion-button
                                    relative
                                    flex
                                    items-center
                                    w-full
                                    py-4
                                    px-5
                                    text-base text-gray-800 text-left
                                    bg-white
                                    border-0
                                    rounded-none
                                    transition
                                    focus:outline-none
                                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"  aria-expanded="true"
                                    aria-controls="collapseOne"> 
                                        <svg className="svg-icon w-7 h-7 text-green-400" viewBox="0 0 20 20" stroke="currentColor" fill="none">
                                            <path d="M10,6.978c-1.666,0-3.022,1.356-3.022,3.022S8.334,13.022,10,13.022s3.022-1.356,3.022-3.022S11.666,6.978,10,6.978M10,12.267c-1.25,0-2.267-1.017-2.267-2.267c0-1.25,1.016-2.267,2.267-2.267c1.251,0,2.267,1.016,2.267,2.267C12.267,11.25,11.251,12.267,10,12.267 M18.391,9.733l-1.624-1.639C14.966,6.279,12.563,5.278,10,5.278S5.034,6.279,3.234,8.094L1.609,9.733c-0.146,0.147-0.146,0.386,0,0.533l1.625,1.639c1.8,1.815,4.203,2.816,6.766,2.816s4.966-1.001,6.767-2.816l1.624-1.639C18.536,10.119,18.536,9.881,18.391,9.733 M16.229,11.373c-1.656,1.672-3.868,2.594-6.229,2.594s-4.573-0.922-6.23-2.594L2.41,10l1.36-1.374C5.427,6.955,7.639,6.033,10,6.033s4.573,0.922,6.229,2.593L17.59,10L16.229,11.373z"></path>
                                        </svg> 
                                        &nbsp;&nbsp; <div className="text-sm leading-5 text-gray-500"> Main Ad #1 </div>  
                                </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body py-4 px-5">
                                    <AdSocialAll id={adList} sid={adSocial[0]} />
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingTwo">
                                <button className="
                                    accordion-button
                                    collapsed
                                    relative
                                    flex
                                    items-center
                                    w-full
                                    py-4
                                    px-5
                                    text-base text-gray-800 text-left
                                    bg-white
                                    border-0
                                    rounded-none
                                    transition
                                    focus:outline-none
                                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                    aria-controls="collapseTwo">
                                    <svg className="w-6 h-6 text-blue-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                                    </svg>
                                    &nbsp;&nbsp; <div className="text-sm leading-5 text-gray-500"> Facebook #2 </div>                                                      
                                </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body py-4 px-5">
                                <AdSocialAll id={adList} sid={adSocial[1]} />
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingThree">
                                <button className="
                                    accordion-button
                                    collapsed
                                    relative
                                    flex
                                    items-center
                                    w-full
                                    py-4
                                    px-5
                                    text-base text-gray-800 text-left
                                    bg-white
                                    border-0
                                    rounded-none
                                    transition
                                    focus:outline-none
                                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">
                                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88" className="w-6 h-6">
                                        <path d="M61.45,0C44.76,0,42.66.07,36.11.37A45.08,45.08,0,0,0,21.2,3.23a29.86,29.86,0,0,0-10.88,7.08,30.26,30.26,0,0,0-7.1,10.88A44.92,44.92,0,0,0,.37,36.11C.08,42.66,0,44.75,0,61.44S.07,80.21.37,86.77a45.08,45.08,0,0,0,2.86,14.91,30.12,30.12,0,0,0,7.08,10.88,30.13,30.13,0,0,0,10.88,7.1,45.17,45.17,0,0,0,14.92,2.85c6.55.3,8.64.37,25.33.37s18.77-.07,25.33-.37a45.17,45.17,0,0,0,14.92-2.85,31.54,31.54,0,0,0,18-18,45.6,45.6,0,0,0,2.86-14.91c.29-6.55.37-8.64.37-25.33s-.08-18.78-.37-25.33a45.66,45.66,0,0,0-2.86-14.92,30.1,30.1,0,0,0-7.09-10.88,29.77,29.77,0,0,0-10.88-7.08A45.14,45.14,0,0,0,86.76.37C80.2.07,78.12,0,61.43,0ZM55.93,11.07h5.52c16.4,0,18.34.06,24.82.36a34,34,0,0,1,11.41,2.11,19,19,0,0,1,7.06,4.6,19.16,19.16,0,0,1,4.6,7.06,34,34,0,0,1,2.11,11.41c.3,6.47.36,8.42.36,24.82s-.06,18.34-.36,24.82a33.89,33.89,0,0,1-2.11,11.4A20.35,20.35,0,0,1,97.68,109.3a33.64,33.64,0,0,1-11.41,2.12c-6.47.3-8.42.36-24.82.36s-18.35-.06-24.83-.36a34,34,0,0,1-11.41-2.12,19,19,0,0,1-7.07-4.59,19,19,0,0,1-4.59-7.06,34,34,0,0,1-2.12-11.41c-.29-6.48-.35-8.42-.35-24.83s.06-18.34.35-24.82a33.7,33.7,0,0,1,2.12-11.41,19,19,0,0,1,4.59-7.06,19.12,19.12,0,0,1,7.07-4.6A34.22,34.22,0,0,1,36.62,11.4c5.67-.25,7.86-.33,19.31-.34Zm38.31,10.2a7.38,7.38,0,1,0,7.38,7.37,7.37,7.37,0,0,0-7.38-7.37ZM61.45,29.89A31.55,31.55,0,1,0,93,61.44,31.56,31.56,0,0,0,61.45,29.89Zm0,11.07A20.48,20.48,0,1,1,41,61.44,20.48,20.48,0,0,1,61.45,41Z"/>
                                    </svg>
                                    &nbsp;&nbsp; <div className="text-sm leading-5 text-gray-500"> Instagram #3 </div> 
                                </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body py-4 px-5">
                                <AdSocialAll id={adList} sid={adSocial[2]} />
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingFour">
                                <button className="
                                    accordion-button
                                    collapsed
                                    relative
                                    flex
                                    items-center
                                    w-full
                                    py-4
                                    px-5
                                    text-base text-gray-800 text-left
                                    bg-white
                                    border-0
                                    rounded-none
                                    transition
                                    focus:outline-none
                                " type="button" data-bs-toggle="collapse" 
                                    data-bs-target="#collapseFour" 
                                    aria-expanded="false"
                                    aria-controls="collapseFour">                                                    
                                    <svg className="w-6 h-6 text-blue-300 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                                    </svg> 
                                    &nbsp;&nbsp; <div className="text-sm leading-5 text-gray-500"> Twitter #4</div>                                                     
                                </button>
                                </h2>
                                <div 
                                id="collapseFour" 
                                className="accordion-collapse collapse" aria-labelledby="headingFour"
                                data-bs-parent="#accordionExample"
                                >
                                <div className="accordion-body py-1 px-1">
                                    <AdSocialAll id={adList} sid={adSocial[4]} />
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingFive">
                                <button className="
                                    accordion-button
                                    collapsed
                                    relative
                                    flex
                                    items-center
                                    w-full
                                    py-4
                                    px-5
                                    text-base text-gray-800 text-left
                                    bg-white
                                    border-0
                                    rounded-none
                                    transition
                                    focus:outline-none
                                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false"
                                    aria-controls="collapseFive">                                                    
                                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" className="w-6 h-6">
                                        <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
                                        <path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
                                        <path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
                                        <path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
                                    </svg>
                                    &nbsp;&nbsp; <div className="text-sm leading-5 text-gray-500"> Google MB #5</div>                                                     
                                </button>
                                </h2>
                                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body py-1 px-1">
                                <AdSocialAll id={adList} sid={adSocial[5]} />
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingSix">
                                <button className="
                                    accordion-button
                                    collapsed
                                    relative
                                    flex
                                    items-center
                                    w-full
                                    py-4
                                    px-5
                                    text-base text-gray-800 text-left
                                    bg-white
                                    border-0
                                    rounded-none
                                    transition
                                    focus:outline-none
                                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false"
                                    aria-controls="collapseSix">                                                    
                                    <svg className="w-6 h-6 text-pink-600 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z" />
                                    </svg>
                                    &nbsp;&nbsp; <div className="text-sm leading-5 text-gray-500"> Pinterest #6</div>                                                     
                                </button>
                                </h2>
                                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body py-1 px-1">
                                    <AdSocialAll id={adList} sid={adSocial[6]} />
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingSeven">
                                <button className="
                                    accordion-button
                                    collapsed
                                    relative
                                    flex
                                    items-center
                                    w-full
                                    py-4
                                    px-5
                                    text-base text-gray-800 text-left
                                    bg-white
                                    border-0
                                    rounded-none
                                    transition
                                    focus:outline-none
                                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false"
                                    aria-controls="collapseSeven">                                                    
                                    <svg xmlns="http://www.w3.org/2000/svg"  className="w-6 h-6" viewBox="0 0 333333 333333" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd">
                                        <path d="M166667 0c92048 0 166667 74619 166667 166667s-74619 166667-166667 166667S0 258715 0 166667 74619 0 166667 0zm10353 156308v37911c0 9616-125 15159 894 17894 1015 2714 3546 5534 6309 7159 3671 2197 7856 3292 12580 3292 8392 0 13354-1107 21654-6559v24918c-7076 3326-13250 5275-18993 6624-5748 1334-11956 2007-18628 2007-7584 0-12048-952-17873-2858-5822-1915-10796-4663-14900-8185-4124-3557-6963-7315-8557-11307-1594-3989-2387-9781-2387-17361v-58141l-22242 3v-23477c6507-2115 13791-5150 18421-9083 4650-3962 8379-8694 11191-14235 2820-5534 4754-12580 5813-21129l26718-3v41443l41436 6 1 31087-41436-6h-1z" fill="#35465c"/>
                                    </svg>
                                    &nbsp;&nbsp; <div className="text-sm leading-5 text-gray-500"> Tumblr #7</div>                                                     
                                </button>
                                </h2>
                                <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body py-1 px-1">
                                <AdSocialAll id={adList} sid={adSocial[7]} />
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingSeven">
                                <button className="
                                    accordion-button
                                    collapsed
                                    relative
                                    flex
                                    items-center
                                    w-full
                                    py-4
                                    px-5
                                    text-base text-gray-800 text-left
                                    bg-white
                                    border-0
                                    rounded-none
                                    transition
                                    focus:outline-none
                                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false"
                                    aria-controls="collapseEight">                                                    

                                    &nbsp;&nbsp; <div className="text-sm leading-5 text-gray-500"> Dubicars Post #8</div>                                                     
                                </button>
                                </h2>
                                <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingSeven"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body py-1 px-1">
                                <AdSocialAll id={adList} sid={adSocial[8]} />
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingSeven">
                                <button className="
                                    accordion-button
                                    collapsed
                                    relative
                                    flex
                                    items-center
                                    w-full
                                    py-4
                                    px-5
                                    text-base text-gray-800 text-left
                                    bg-white
                                    border-0
                                    rounded-none
                                    transition
                                    focus:outline-none
                                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false"
                                    aria-controls="collapseNine">                                                    

                                    &nbsp;&nbsp; <div className="text-sm leading-5 text-gray-500"> Dubicars title #9</div>                                                     
                                </button>
                                </h2>
                                <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingSeven"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body py-1 px-1">
                                <AdSocialAll id={adList} sid={adSocial[9]} />
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingSeven">
                                <button className="
                                    accordion-button
                                    collapsed
                                    relative
                                    flex
                                    items-center
                                    w-full
                                    py-4
                                    px-5
                                    text-base text-gray-800 text-left
                                    bg-white
                                    border-0
                                    rounded-none
                                    transition
                                    focus:outline-none
                                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false"
                                    aria-controls="collapseTen">                                                    

                                    &nbsp;&nbsp; <div className="text-sm leading-5 text-gray-500"> Dubizzle title #10</div>                                                     
                                </button>
                                </h2>
                                <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingSeven"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body py-1 px-1">
                                <AdSocialAll id={adList} sid={adSocial[10]} />
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingEleven">
                                <button className="
                                    accordion-button
                                    collapsed
                                    relative
                                    flex
                                    items-center
                                    w-full
                                    py-4
                                    px-5
                                    text-base text-gray-800 text-left
                                    bg-white
                                    border-0
                                    rounded-none
                                    transition
                                    focus:outline-none
                                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false"
                                    aria-controls="collapseEleven">                                                    
                                    &nbsp;&nbsp; <div className="text-sm leading-5 text-gray-500"> Shops title #11</div>                                                     
                                </button>
                                </h2>
                                <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body py-1 px-1">
                                <AdSocialAll id={adList} sid={adSocial[11]} />
                                </div>
                                </div>
                            </div>
                        </div>           
                        
                    </div>
                    {/* ---- Right Content End ---- */}
                    </div>
                    :
                        ''
                    }    
                   {/* ---- Content End ---- */}
                </div>
            </div>    
        </Layout>
        </>     
    );
}
    
export default AdListTodayRight