import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Papa from "papaparse";
import _ from "lodash";
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig'
import Layout from '../Layout';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()


const EmailAddUserlist = ({}) => {   
    const url = '/emailadduserlist';

    const [array, setArray] = useState([]);
    const [state, setState] = useState({
        csvfile: null
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setState({
            csvfile: e.target.files[0]
        });
    };

    function importCSV(e) {
        e.preventDefault();
        const { csvfile } = state;
    
        csvfile &&
          Papa.parse(csvfile, {
            header: true,
            dynamicTyping: true,
            complete: updateData
        });
    }

    function updateData(result) {
        setState({
          csvfile: null
        });
        let data = result.data;
        _.map(data, function(obj) {
           if(obj['CustomModule2 Id'] !== null)
           {
                let objResult = { 
                    'name': obj['Customer Name'] || '', 
                    'email': obj['Email'] || '',               
                    'phone': obj['Mobile'] || '', 
                    'brand': obj['BRAND'] || '', 
                    'model': obj['MODEL'] || '', 
                    'ownername': obj['CustomModule Owner'] || ''
                };      
                array.push(objResult); 
            }                   
        });    
        setLoading(true)
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();               
        if(array.length > 0) {    
            try{        
                // console.log('api input:', array)
                const token =JSON.parse(localStorage.getItem("authuser")).access_token
                const headers = { 
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`,
                };
                const res = await axios.post(`${serverAPI}/emailusers`, array, {headers})
                if(res.data) {
                    // console.log(res.data)
                    toast.success("This week data updated")   
                    setArray([])    
                    setLoading(false)
                    setState({
                        csvfile: null,
                    }) 
                }              
            }catch(err){
                console.log(err)
                toast.error("Server error. Try Again !")
            }
        } else {
            toast.error('No EmailIDs. Please import the CSV')
        }         
    };

    const handleOnClear = (e) => {
        e.preventDefault();
        setArray([])
        setLoading(false)
        setState({
            csvfile: null,
        })
        window.location.href=url
    };

    return (
        <>      
        <Layout>
            <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">    
                    <div className="grid grid-cols-3 gap-4">
                        <div><h1 className="mb-3 font-semibold text-lg lg:font-xl md:text-lg ">Add Weekly Users List </h1></div>
                        <div className="invisible ..."></div>
                        <div><Link to="/files/BUYERS_WEEKLY_SAMPLE.csv" target="_blank" download className="text-xs font-small underline">Download Zoho Sample CSV Template</Link></div>
                    </div>             
                    <div className="inline-block min-w-full pt-2 pr-20 pl-20 pb-2 overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">  
                        <div className="py-2 px-1">
                        <form className="form sm:w-full leading-normal mt-3" noValidate="novalidate" id="kt_sign_in_form" action="#" > 
                            <span className="text-black font-sm">[.csv file]</span> &nbsp;                        
                            <input
                            className="upload-input"
                            type="file"
                            name="file"
                            placeholder={null}
                            onChange={handleChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button id="kt_sign_in_submit" className="group relative justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
                            onClick={importCSV}
                            >
                            <span className="text-white font-sm">Import CSV</span>
                            </button>                          
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button id="kt_sign_in_submit" className="group relative justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
                            onClick={(e) => {
                                handleOnSubmit(e);
                            }}
                            >
                            <span className="text-white font-sm">Add this week list</span>                            
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button id="kt_sign_in_submit" className="group relative justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
                            onClick={(e) => {
                                handleOnClear(e);
                            }}
                            >
                            <span className="text-white font-sm">Clear</span>
                            </button>
                        </form>  
                        </div>
                        <div>
                        {loading && 
                        <div>
                            {array.length > 0 && 
                            <table>
                            <thead>
                                <tr>
                                    <th>                               
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                    Total count: {array.length}
                                    </th>
                                </tr>
                                <tr key={"header"}>                              
                                    {Object.keys(array[0]).map((key) => (
                                    <th 
                                    className="px-6 py-3 text-xs font-semibold leading-4 tracking-wider uppercase text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                        {key}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="bg-white"> 
                            {array.map((item) => (
                                <tr key={item.id}>
                                {Object.values(item).map((val) => (
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                        {val}</td>
                                ))}
                                </tr>
                            ))}
                            </tbody> 
                            </table>
                            }
                        </div>
                        }
                        </div>                      
                    </div>
                </div>
            </div>
        </Layout>      
        </>
    );
}
    
export default EmailAddUserlist