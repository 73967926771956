import React, {useEffect, useState} from 'react'

const ClipboardCopy = ({copyText}) => { 
  const [isCopied, setIsCopied] = useState(false);
 
  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return false
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  return (  
    <>
        <button type="button" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
            onClick={()=>handleCopyClick()}
            >
            <span className="text-white font-sm"> {isCopied ? 'Copied!' : 'Copy'}</span>
        </button>
    </>
  );
}

export default ClipboardCopy