import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../Layout";
import axios from "axios";
import { serverAPI } from "../../utils/envConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const AdFormEditToday = ({}) => {
  // Authentication and Authorization
  const token = JSON.parse(localStorage.getItem("authuser")).access_token;
  const myRole = JSON.parse(localStorage.getItem("authuser")).role;

  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [load, setLoad] = useState(false);
  const [adData, setAdData] = useState({
    carAdId: "",
    carstockno: "",
    carname: "",
    caryear: "",
    cartitle: "",
    carprice: "",
    caremi: "",
    carodometer: "",
    carwarranty: "",
    carservice: "",
    carcylinder: "",
    carwheel: "",
    carspec: "",
    offers: "",
    description: "",
    options: "",
    cashbuyers: "",
    financebuyers: "",
    booking: "",
    addexpenses: "",
    sellyourcar: "",
    carwebsite: "",
    carwebsitepin: "",
    carwebsitetumblr: "",
    carlocationgoogle: "",
    carshowroom: "",
    carhashinsta: "",
    carhashlnkd: "",
    carhashtwitter: "",
    contactteams: [],
    createdate: "",
    createby: "",
  });

  const [stno, setStno] = useState("");
  const [cn, setCn] = useState("");
  const [contact, setContact] = useState([]);
  const [contactEdit, setContactEdit] = useState([]);
  const [contactSingleEdit, setContactSingleEdit] = useState([]);
  const [salesTeam, setSalesTeam] = useState([]);
  const [msg, setMsg] = useState("");

  const [year, setYear] = useState(0);
  const [price, setPrice] = useState(0);
  const [emi, setEMI] = useState(0);
  const [vat, setVat] = useState("");

  const [lastOffer, setLastOffer] = useState("");

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const validate = () => {
    if (adData.carstockno === "") {
      toast.error("Please enter Car Stock Number");
      return false;
    } else if (adData.carname === "") {
      toast.error("Please enter Car Name");
      return false;
    } else if (adData.caryear === "" || adData.caryear === 0) {
      toast.error("Please enter Car Year");
      return false;
    } else if (adData.carprice === "" || adData.carprice === 0) {
      toast.error("Please enter Price");
      return false;
    } else if (adData.caremi === "" || adData.caremi === 0) {
      toast.error("Please enter EMI");
      return false;
    } else if (adData.carodometer === "") {
      toast.error("Please enter Odometer");
      return false;
    } else if (adData.carcylinder === "") {
      toast.error("Please enter Cylinder");
      return false;
    } else if (adData.carwheel === "") {
      toast.error("Please enter Wheel size");
      return false;
    } else if (adData.carspec === "") {
      toast.error("Please enter Spec");
      return false;
    } else if (adData.description === "") {
      toast.error("Please enter Description");
      return false;
    } else if (adData.contactteams === "") {
      toast.error("Update Contact Teams");
      return false;
    } else if (adData.carwebsite === "") {
      toast.error("Please enter Website Link");
      return false;
    } else if (adData.carlocationgoogle === "") {
      toast.error("Please enter Location");
      return false;
    } else if (adData.carshowroom === "") {
      toast.error("Please enter Showroom timings");
      return false;
    } else if (adData.carhashinsta === "") {
      toast.error("Please enter Hash Tags - Instagram");
      return false;
    } else if (adData.carhashtwitter === "") {
      toast.error("Please enter Hash Tags - Twitter");
      return false;
    } else {
      return true;
    }
  };
  const handleContacts = async (e) => {
    e.preventDefault();
    // let arr2 = contact.filter((c) => salesTeam.some((s) => c.id === s.id));
    setContactEdit(salesTeam);
    setIsShowing(true);
  };
  const removeContacts = async (e) => {
    e.preventDefault();
    setContactEdit([]);
    setIsShowing(false);
  };

  // Edit Ad Post Info in DB
  const editAdPost = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (contactSingleEdit.length > 0) {
        adData.contactteams = contactSingleEdit;
      } else {
        let init = adData.contactteams.map((id) => id.id);
        adData.contactteams = init;
      }

      //   if (contactEdit.length) {
      //     adData.contactteams = contactEdit;
      //   }
      try {
        console.log(adData);
        const token = JSON.parse(localStorage.getItem("authuser")).access_token;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const res = await axios
          .patch(`${serverAPI}/adGen/${adData["carAdId"]}`, adData, { headers })
          .then((user) => {
            navigate(-1);
          })
          .catch((err) => {
            toast.error("Ad Post not modified with changes !");
          });
      } catch (err) {
        // console.log(err)
        toast.error("Server error. Try Again !");
      }
    }
  };

  useEffect(async () => {
    // Get Today Post Info
    if (searchParams.has("id")) {
      const adID = searchParams.get("id");
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const res = await axios
          .get(`${serverAPI}/adGen/${adID}`, { headers })
          .then((adedit) => {
            setAdData({
              carAdId: adedit.data.id,
              carstockno: adedit.data.carstockno,
              carname: adedit.data.carname,
              caryear: adedit.data.caryear,
              cartitle: adedit.data.cartitle,
              carprice: adedit.data.carprice,
              caremi: adedit.data.caremi,
              carodometer: adedit.data.carodometer,
              carwarranty: adedit.data.carwarranty,
              carservice: adedit.data.carservice,
              carcylinder: adedit.data.carcylinder,
              carwheel: adedit.data.carwheel,
              carspec: adedit.data.carspec,
              offers: adedit.data.offers,
              description: adedit.data.description,
              options: adedit.data.options,
              cashbuyers: adedit.data.cashbuyers,
              financebuyers: adedit.data.financebuyers,
              booking: adedit.data.booking,
              addexpenses: adedit.data.addexpenses,
              sellyourcar: adedit.data.sellyourcar,
              carwebsite: adedit.data.carwebsite,
              carwebsitepin: adedit.data.carwebsitepin,
              carwebsitetumblr: adedit.data.carwebsitetumblr,
              carlocationgoogle: adedit.data.carlocationgoogle,
              carshowroom: adedit.data.carshowroom,
              carhashinsta: adedit.data.carhashinsta,
              carhashlnkd: adedit.data.carhashlnkd,
              carhashtwitter: adedit.data.carhashtwitter,
              contactteams: adedit.data.contactteams,
              createdate: adedit.data.createdate,
              createby: adedit.data.createby,
            });
            axios
              .get(`${serverAPI}/salesTeam`, { headers })
              .then((members) => {
                const sTeam = members.data.filter((st) => st.status === true);
                setSalesTeam(sTeam);
                let arr2 = adedit.data.contactteams.filter((c) =>
                  sTeam.some((s) => c.id === s.id)
                );
                if (arr2.length != adedit.data.contactteams.length) {
                  setMsg("(Contact team has inactive member.)");
                }
                setContact(adedit.data.contactteams);
              })
              .catch((err, res) => {
                // console.log(err)
              });

            setLoad(true);
          })
          .catch((err, res) => {
            // console.log(err)
          });
      } catch (err) {
        // console.log(err)
      }
    }
    if (searchParams.has("st")) {
      const stno = searchParams.get("st");
      setStno(stno);
    }

    if (searchParams.has("car")) {
      const cn = searchParams.get("car");
      setCn(cn);
    }

    async function getOffer() {
      try {
        const res = await axios
          .get(`${serverAPI}/monthlyOfferLatest`, { headers })
          .then((result) => {
            setLastOffer(result.data[0].offer);
            console.log(result.data[0].offer);
          })
          .catch((err, res) => {
            // console.log(err)
          });
      } catch (err) {
        // console.log(err)
      }
    }
    getOffer();
  }, []);

  // Calculate EMI based on Price and Year of Car
  useEffect(() => {
    setAdData({ ...adData, carprice: price });
    let priceCalc = parseInt(price);
    let yearCalc = parseInt(adData["caryear"]);
    let emiCalc = "";
    let emiyear = 0;
    if (yearCalc === 2015) {
      emiCalc = Math.round((priceCalc + priceCalc * 0.035 * 3) / 36);
      emiyear = 4;
      emiCalc = `${emiCalc}`;
    }
    // else if (yearCalc === 2013) {
    //   emiCalc = Math.round((priceCalc + priceCalc * 0.035 * 3) / 36);
    //   emiyear = 3;
    //   emiCalc = `${emiCalc}`;
    // }
    else if (yearCalc === 2016) {
      emiCalc = Math.round((priceCalc + priceCalc * 0.035 * 4) / 48);
      emiyear = 5;
      emiCalc = `${emiCalc}`;
    } else {
      emiCalc = Math.round((priceCalc + priceCalc * 0.035 * 5) / 60);
      emiCalc = `${emiCalc}`;
    }
    setEMI(emiCalc);
  }, [price]);

  useEffect(() => {
    setAdData({ ...adData, caremi: emi });
  }, [emi]);

  // Set Car Price
  const handlePrice = (e) => {
    setPrice(e.target.value);
  };
  const handleVat= (e) => {
    setVat(e.target.value);
    setAdData({ ...adData, addexpenses:e.target.value});
  };

  // set Car Year, Price and EMI to default
  useEffect(() => {
    setAdData({ ...adData, caryear: year, carprice: 0, caremi: 0 });
  }, [year]);

  // Handle Year
  const handleYear = (e) => {
    setYear(e.target.value);
    setPrice(0);
    setEMI(0);
  };

  // Set Other Input Variables
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdData({ ...adData, [name]: value });
  };

  const contactCheckboxTrigger = (e, checkBoxId) => {
    var target = document.getElementById(checkBoxId);
    const index = contactSingleEdit.indexOf(target.value);
    if (index === -1) {
      target.checked = true;
      // creating a new array with [], so the original one stays intact
      setContactSingleEdit([...contactSingleEdit, target.value]);
    } else {
      target.checked = false;
      // Array.filter also creates new array
      setContactSingleEdit(contactSingleEdit.filter((char, i) => i !== index));
    }
  };

  const updateOffer = (e) => {
    e.preventDefault();
    setAdData({ ...adData, offers: lastOffer });
  };

  const [isShowing, setIsShowing] = useState(false);

  return (
    <Layout>
      <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
        <div className="container mx-auto">
          <div>
            <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">
              Edit Ad Generations
            </h1>
          </div>
          {/* ---- Content Start ---- */}
          {load ? (
            <form name="adpost" onSubmit={(e) => editAdPost(e)}>
              <div className="space-y-5">
                <div className="bg-white shadow px-4 py-5 mb-2 sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-3 md:gap-4">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="contactteam"
                          className="block text-md font-medium text-gray-700"
                        >
                          <b>Stock No:</b> {stno}
                        </label>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="contactteam"
                          className="block text-md font-medium text-gray-700"
                        >
                          <b>Car Name:</b> {cn}
                        </label>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6">
                        <button
                          type="button"
                          id="kt_sign_in_submit"
                          className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
                          onClick={() => navigate(-1)}
                        >
                          <span className="text-white font-sm">Go Back</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow px-4 py-5 mb-2 sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-6 md:gap-4">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium leading-6 text-gray-900">
                        Contact Details:{" "}
                      </h3>
                      <p className="text-sm text-red-500">{msg}</p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6">
                          {Array.isArray(contact) &&
                            contact.length &&
                            contact.map((item, index) => (
                              <div>
                                <label
                                  htmlFor="contactteam"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  {item.name}
                                </label>
                                <label
                                  htmlFor="contactteam"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  {item.phone}
                                </label>
                                {/* <label
                                  htmlFor="contactteam"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  {item.whatsupdub}
                                </label> */}
                                <label
                                  htmlFor="contactteam"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  {item.whatsupfb}
                                </label>
                                <br />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <button
                        onClick={(e) => handleContacts(e)}
                        className=" text-white group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
                      >
                        Edit contacts
                      </button>
                      <Transition show={isShowing}>
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6 border-2 p-2 border-gray-200">
                            {Array.isArray(contactEdit) &&
                              contactEdit.length &&
                              contactEdit.map((item, index) => (
                                <div
                                  onClick={(e) =>
                                    contactCheckboxTrigger(
                                      e,
                                      `contactEditCheckBox${index}`
                                    )
                                  }
                                >
                                  <input
                                    id={`contactEditCheckBox${index}`}
                                    type="checkbox"
                                    onChange={(e) => e.stopPropagation()}
                                    value={item.id}
                                  />
                                  <label
                                    htmlFor="contactteam"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    {item.name}
                                  </label>
                                  <label
                                    htmlFor="contactteam"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    {item.phone}
                                  </label>
                                  {/* <label
                                    htmlFor="contactteam"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    {item.whatsupdub}
                                  </label> */}
                                  <label
                                    htmlFor="contactteam"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    {item.whatsupfb}
                                  </label>
                                  <br />
                                </div>
                              ))}
                          </div>
                        </div>
                      </Transition>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-1">
                      <button
                        onClick={(e) => removeContacts(e)}
                        className=" text-white group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
                <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <p className="mt-0 mb-2 text-sm text-gray-500">
                    (* Mandatory fields)
                  </p>
                  <div className="md:grid md:grid-cols-6 md:gap-4">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium leading-6 text-gray-900">
                        Car Information:{" "}
                      </h3>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                          <label
                            htmlFor="carstockno"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Car Stock Number (Easy cars)
                          </label>
                          <input
                            type="number"
                            name="carstockno"
                            value={adData["carstockno"] || ""}
                            onChange={handleChange}
                            id="carstockno"
                            autoComplete="carstockno"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="carname"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Car Name
                          </label>
                          <input
                            type="text"
                            name="carname"
                            value={adData["carname"] || ""}
                            onChange={handleChange}
                            id="carname"
                            autoComplete="carname"
                            placeholder="Porsche Cayenne GTS"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-2">
                          <label
                            htmlFor="carhashlnkd"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Trim (Only for titles)
                          </label>
                          <input
                            type="text"
                            name="carhashlnkd"
                            autoComplete="off"
                            value={adData["carhashlnkd"] || ""}
                            onChange={handleChange}
                            id="carhashlnkd"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-2">
                          <label
                            htmlFor="cartitle"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Title (Extra)
                          </label>
                          <input
                            type="text"
                            name="cartitle"
                            value={adData["cartitle"] || ""}
                            onChange={handleChange}
                            id="cartitle"
                            autoComplete="cartitle"
                            placeholder="Immaculate Condition"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-2">
                          <label
                            htmlFor="caryear"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Car Year
                          </label>
                          <input
                            type="number"
                            name="caryear"
                            value={adData["caryear"] || ""}
                            onChange={handleYear}
                            id="caryear"
                            autoComplete="caryear"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                          <label
                            htmlFor="carprice"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Price (AED)
                          </label>
                          <input
                            type="number"
                            name="carprice"
                            value={adData["carprice"] || ""}
                            onChange={handlePrice}
                            // onChange={handleChange}
                            id="carprice"
                            autoComplete="carprice"
                            placeholder="179900"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                          <label
                            htmlFor="caremi"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * EMI (AED)
                          </label>
                          <input
                            type="text"
                            name="caremi"
                            value={adData["caremi"] || emi}
                            onChange={handleChange}
                            id="caremi"
                            placeholder="3523"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                    <label
                      htmlFor="carprice"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * VAT
                    </label>
                    <select
                      id="vat"
                      name="vat"
                      onChange={handleVat}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select</option>
                      <option value="Inclusive of VAT">Inclusive</option>
                      <option value="Exclusive of VAT">Exclusive</option>

                    </select>
                  </div>
                        <div className="col-span-6 sm:col-span-2">
                          <label
                            htmlFor="carodometer"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Odometer (Kms)
                          </label>
                          <input
                            type="number"
                            name="carodometer"
                            value={adData["carodometer"] || ""}
                            onChange={handleChange}
                            id="carodometer"
                            autoComplete="carodometer"
                            placeholder="43700"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-2">
                          <label
                            htmlFor="carwarranty"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Warranty
                          </label>
                          <input
                            type="text"
                            name="carwarranty"
                            value={adData["carwarranty"] || ""}
                            onChange={handleChange}
                            id="carwarranty"
                            autoComplete="carwarranty"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-2">
                          <label
                            htmlFor="carservice"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Service
                          </label>
                          <input
                            type="text"
                            name="carservice"
                            value={adData["carservice"] || ""}
                            onChange={handleChange}
                            id="carservice"
                            autoComplete="carservice"
                            placeholder="Service"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-1">
                          <label
                            htmlFor="carcylinder"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Car Cylinders
                          </label>
                          <input
                            type="text"
                            name="carcylinder"
                            value={adData["carcylinder"] || ""}
                            onChange={handleChange}
                            id="carcylinder"
                            autoComplete="carcylinder"
                            placeholder="6"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-1">
                          <label
                            htmlFor="carwheel"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Car Wheel Size
                          </label>
                          <input
                            type="text"
                            name="carwheel"
                            value={adData["carwheel"] || ""}
                            onChange={handleChange}
                            id="carwheel"
                            autoComplete="carwheel"
                            placeholder='21"'
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-1">
                          <label
                            htmlFor="carspec"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Car Specification
                          </label>
                          <input
                            type="text"
                            name="carspec"
                            value={adData["carspec"] || ""}
                            onChange={handleChange}
                            id="carspec"
                            autoComplete="carspec"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-6 md:gap-4">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium leading-6 text-gray-900">
                        Offers:{" "}
                      </h3>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div>
                        <div className="mt-1">
                          <textarea
                            id="offers"
                            name="offers"
                            value={adData["offers"] || ""}
                            onChange={handleChange}
                            rows={6}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="March Madness is here! It's time to make your move. Buy your dream car today!
                                Take advantage of our Limited Time Offer:
                                - One Year Warranty
                                - One Free Service
                                Offer is Valid until 31st March 2022! *Ts&amp;Cs apply. **Offer Valid on Selected Cars only"
                          />
                          <button
                            onClick={(e) => updateOffer(e)}
                            className=" text-white group relative flex justify-center py-2 px-4 mt-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
                          >
                            Update Offer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-6 md:gap-4">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium leading-6 text-gray-900">
                        * Description:{" "}
                      </h3>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div>
                        <div className="mt-1">
                          <textarea
                            id="description"
                            name="description"
                            value={adData["description"] || ""}
                            onChange={handleChange}
                            rows={6}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="The Porsche Cayenne is one of the most appealing midsize luxury crossovers thanks to its sophisticated
                                engineering, impeccable construction, immense features list and surprisingly useful cabin.
                                The 3.6L V6 engine is truly in a class of its own. This sophisticated engineered machine strikes a
                                harmonious balance of speed and acceleration.
                                In excellent condition, the elegant White color exterior combined with the luxurious Brown leather
                                inside, will leave your guests begging you for a ride home."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-6 md:gap-4">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium leading-6 text-gray-900">
                        * Options:{" "}
                      </h3>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div>
                        <div className="mt-1">
                          <textarea
                            id="options"
                            name="options"
                            value={adData["options"] || ""}
                            onChange={handleChange}
                            rows={6}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="-Bose Sound System
                                -Rear Camera
                                -Sunroof
                                -Electric/Memory Seats
                                -Cruise Control
                                -Climate Control
                                -IsoFix Seats
                                -Sports Modes
                                -Electric Tailgate
                                -Navigation System
                                -Keyless Entry/Start"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-6 md:gap-4">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium leading-6 text-gray-900">
                        Cash Buyers:{" "}
                      </h3>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div>
                        <div className="mt-1">
                          <textarea
                            id="cashbuyers"
                            name="cashbuyers"
                            value={adData["cashbuyers"] || ""}
                            onChange={handleChange}
                            rows={4}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Please provide:
                                1- Emirates ID
                                2- Driving License"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-6 md:gap-4">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium leading-6 text-gray-900">
                        Finance Buyers:{" "}
                      </h3>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div>
                        <div className="mt-1">
                          <textarea
                            id="financebuyers"
                            name="financebuyers"
                            value={adData["financebuyers"] || ""}
                            onChange={handleChange}
                            rows={6}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Required Bank finance documents are as follows:
                                —
                                
                                Employed:
                                1- Salary Certificate
                                2- 3 month bank statement (stamped)
                                3- Passport &amp; Visa copies)
                                4- Emirates ID copy
                                ( Note: Please contact us if you have received only one or no salaries and work for a listed company)
                                —
                                Self Employed:
                                1- Trade License
                                2- MOA
                                3- Passport copies of all partners
                                4- Passport and visa copies of applicant
                                5- Emirates ID
                                6- 3 month personal bank statement
                                7- 3 month company bank statement
                                —
                                Companies:
                                1- Trade License
                                2- MOA
                                3- Passport copies of all partners
                                4- 3 month company bank statement"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-6 md:gap-4">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium leading-6 text-gray-900">
                        Booking Options:{" "}
                      </h3>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div>
                        <div className="mt-1">
                          <textarea
                            id="booking"
                            name="booking"
                            value={adData["booking"] || ""}
                            onChange={handleChange}
                            rows={6}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="To start the application we require a deposit of AED 5,000 through one of the following methods :
                                -
                                1- Credit/Debit card - to be refunded in cash once vehicle is registered.
                                —
                                2- Cash - to be refunded as Cash once vehicle is registered.
                                —
                                3- Cheque - Not to be cashed - to be returned once vehicle is registered.
                                —
                                Applicable Terms &amp; Conditions to be presented upon the time of booking."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-6 md:gap-4">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium leading-6 text-gray-900">
                        Additional Information:{" "}
                      </h3>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div>
                        <div className="mt-1">
                          <textarea
                            id="addexpenses"
                            name="addexpenses"
                            value={adData["addexpenses"] || ""}
                            onChange={handleChange}
                            rows={6}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                            // placeholder="If its consignment
                            //     1- Registration: AED 1,100
                            //     2- Insurance: TBC
                            //     If not
                            //     1- Registration: AED 945
                            //     2- Insurance: TBC
                            //     3- VAT 5%
                            //     Or
                            //     If not
                            //     1- Registration: AED 945
                            //     2- Insurance: TBC"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-6 md:gap-4">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium leading-6 text-gray-900">
                        Sell your car:{" "}
                      </h3>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div>
                        <div className="mt-1">
                          <textarea
                            id="sellyourcar"
                            name="sellyourcar"
                            value={adData["sellyourcar"] || ""}
                            onChange={handleChange}
                            rows={6}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Simply fill out the form by clicking the link below :
                                —
                                https://albacars.ae/sell-used-car-dubai/
                                —
                                We pay cash and pay for Bank early settlements."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-6 md:gap-4">
                    <div className="md:col-span-1">
                      <h3 className="text-base font-medium leading-6 text-gray-900">
                        Contact Information:{" "}
                      </h3>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="carwebsite"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Website Link
                          </label>
                          <input
                            type="text"
                            name="carwebsite"
                            value={adData["carwebsite"] || ""}
                            onChange={handleChange}
                            id="carwebsite"
                            autoComplete="carwebsite"
                            placeholder="www.albacars.ae"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="carwebsitepin"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Car Link
                          </label>
                          <input
                            type="text"
                            name="carwebsitepin"
                            value={adData["carwebsitepin"] || ""}
                            onChange={handleChange}
                            id="carwebsitepin"
                            autoComplete="carwebsitepin"
                            placeholder="https://www.albacars.ae/buy-used-cars/vehicle/3740-Mercedes-Benz-GLE-400-4Matic-AMG-Kit
                                    Empty"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="carlocationgoogle"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Location - Googlemaps
                          </label>
                          <input
                            type="text"
                            name="carlocationgoogle"
                            value={adData["carlocationgoogle"] || ""}
                            onChange={handleChange}
                            id="carlocationgoogle"
                            autoComplete="carlocationgoogle"
                            placeholder="https://goo.gl/maps/XrGnafTmhQQuRqMV9"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="carshowroom"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Showroom Timings
                          </label>
                          <input
                            type="text"
                            name="carshowroom"
                            value={adData["carshowroom"] || ""}
                            onChange={handleChange}
                            id="carshowroom"
                            autoComplete="carshowroom"
                            placeholder="Sunday to Saturday (10:00 AM - 10:00 PM)"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="carhashinsta"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Hash Tags - Instagram
                          </label>
                          <textarea
                            type="text"
                            name="carhashinsta"
                            value={adData["carhashinsta"] || ""}
                            onChange={handleChange}
                            id="carhashinsta"
                            autoComplete="carhashinsta"
                            placeholder="#porschecayenne #porscheUAE #albacars #luxurycars #usedcarsshowroom"
                            rows={3}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="carhashtwitter"
                            className="block text-sm font-medium text-gray-700"
                          >
                            * Hash Tags - Twitter
                          </label>
                          <input
                            type="text"
                            name="carhashtwitter"
                            value={adData["carhashtwitter"] || ""}
                            onChange={handleChange}
                            id="carhashtwitter"
                            autoComplete="carhashtwitter"
                            placeholder="#porschecayenne #porscheUAE #albacars #luxurycars #usedcarsshowroom"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-6">
                          <button
                            id="kt_sign_in_submit"
                            className="ml-8 whitespace-nowrap inline-flex items-center justify-right px-4 py-2 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
                          >
                            <span className="text-white font-sm">
                              Edit Changes
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            ""
          )}
          {/* ---- Content End ---- */}
        </div>
      </div>
    </Layout>
  );
};

export default AdFormEditToday;
