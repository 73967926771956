import './App.css';
import { useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import { UserContext } from './contexts/UserContext';
import { TeamContext } from './contexts/TeamContext';
import { SalesTeamContext } from './contexts/SalesTeamContext';
import ProtectedRoutes from './components/ProtectedRoutes';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import Home from './components/Home';
import AddUser from './components/adminusers/AddUser';
import EditUser from './components/adminusers/EditUser';
import ListUser from './components/adminusers/ListUser';
import Exports from './components/Exports';
import ListTeam from './components/purchase/ListTeam';
import AddTeam from './components/purchase/AddTeam';
import EditTeam from './components/purchase/EditTeam';
import RemoveTeam from './components/purchase/RemoveTeam';
import AddSchedule from './components/schedule/addSchedule';
import ListSchedule from './components/schedule/ListSchedule';
import EditSchedule from './components/schedule/editSchedule';
import ListDealFinal from './components/downloads/ListDealFinal';
import ListAppFinal from './components/downloads/ListAppFinal';
import ListDeal from './components/downloads/ListDeal';
import ListApp from './components/downloads/ListApp';
import EmailAddUserlist from './components/weeklyemail/AddEmailUserlist';
import ListSalesTeam from './components/salesteam/ListSalesTeam';
import AddSalesTeam from './components/salesteam/AddSalesTeam';
import EditSalesTeam from './components/salesteam/EditSalesTeam';
import RemoveSalesTeam from './components/salesteam/RemoveSalesTeam';
import AdFormAdd from './components/adforms/AdFormAdd'
import AdFormListToday from './components/adforms/AdFormListToday'
import AdListTodayRight from './components/adforms/AdListTodayRight';
import AdFormEditToday from './components/adforms/AdFormEditToday';
import AdFormList from './components/adforms/AdFormList';
import Unsubscribe from './components/Unsubscribe';
import ListOtherFinal from './components/downloads/ListOtherFinal';
import ListAppKF from './components/downloads/ListAppKF';
import AddOffer from './components/AddOffer';
import EmailUnsubscribe from './components/EmailUnsubscribe';
import ListDealers from './components/dealers/ListDealers';
import EditDealers from './components/dealers/EditDealers';
import AddDealers from './components/dealers/AddDealers';
import RemoveDealer from './components/dealers/RemoveDealer';
import FilterDealers from './components/filter/FilterDealers';

function App() {
  const [users, setUsers] = useState([]);
  const usersValue = useMemo(() => ({ users, setUsers }), [users, setUsers]);
  const [purchaseTeam, setPurchaseTeam] = useState([]);
  const value = useMemo(() => ({ purchaseTeam, setPurchaseTeam }), [purchaseTeam, setPurchaseTeam]);
  const [salesTeam, setSalesTeam] = useState([]);
  const SalesTeamValue = useMemo(() => ({ salesTeam, setSalesTeam }), [salesTeam, setSalesTeam]);

  return (
    <BrowserRouter>
      <>
      <UserContext.Provider value={usersValue}>   
      <TeamContext.Provider value={value}>   
      <SalesTeamContext.Provider value={SalesTeamValue}>    
        <Routes>          
          <Route path="/" element={<Login />} />
          <Route path="/resetpassword" element={<ResetPassword />} />   
          <Route element={<ProtectedRoutes />}>           
            <Route path="/home" element={<Home />} />
            <Route path="/adduser" element={<AddUser />} />
            <Route path="/edituser" element={<EditUser />} />
            <Route path="/listuser" element={<ListUser />} />
            <Route path="/exports" element={<Exports />} />
            <Route path="/listdealfinal" element={<ListDealFinal />} />
            <Route path="/listappfinal" element={<ListAppFinal />} />
            <Route path="/listotherfinal" element={<ListOtherFinal />} />
            <Route path="/listappkf" element={<ListAppKF />} />
            <Route path="/listdeal" element={<ListDeal />} />
            <Route path="/listapp" element={<ListApp />} />
            <Route path="/purchaseteam" element={<ListTeam />} />
            <Route path="/addteam" element={<AddTeam />} />
            <Route path="/editteam" element={<EditTeam />} />
            <Route path="/removeteam" element={<RemoveTeam />} />
            <Route path="/scheduleteam" element={<AddSchedule />} />
            <Route path="/listschedule" element={<ListSchedule/>} />
            <Route path="/editschedule" element={<EditSchedule />} />
            <Route path="/emailadduserlist" element={<EmailAddUserlist />} />
            <Route path="/listsalesteam" element={<ListSalesTeam />} />
            <Route path="/addsalesteam" element={<AddSalesTeam />} />
            <Route path="/editsalesteam" element={<EditSalesTeam />} />
            <Route path="/removesalesteam" element={<RemoveSalesTeam />} />
            <Route path="/adformadd" element={<AdFormAdd />} />
            <Route path="/adformlisttoday" element={<AdFormListToday />} />
            <Route path="/adformlisttodayright" element={<AdListTodayRight />} />
            <Route path="/adformedittoday" element={<AdFormEditToday />} />
            <Route path="/adformlist" element={<AdFormList />} />
            <Route path="/addoffer" element={<AddOffer/>} />   
            <Route path="/emailunsubscribe" element={<EmailUnsubscribe/>} />       
            <Route path="/listdealers" element={<ListDealers/>} />        
            <Route path="/editdealer" element={<EditDealers/>} />      
            <Route path="/adddealer" element={<AddDealers/>} />     
            <Route path="/removedealer" element={<RemoveDealer />} />               
            <Route path="/filter-dealers" element={<FilterDealers/>} />     
          </Route>
          <Route path="/newsletter/unsubscribe/:email" element={<Unsubscribe />} />           
        </Routes>
      </SalesTeamContext.Provider>
      </TeamContext.Provider>   
      </UserContext.Provider> 
      </>
    </BrowserRouter>
  );
}

export default App;
