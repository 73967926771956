import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import Layout from '../Layout'
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const AdFormListToday = ({}) => { 
    const [adList, setAdList] = useState([])

    // Created Date
    let currentDate = moment();

    useEffect( async() => {
        try {
            const token =JSON.parse(localStorage.getItem("authuser")).access_token
            const headers = { 
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${token}`,
            };
            const res = await axios.get(`${serverAPI}/adGen`,{headers})
            .then(adlists => {              
                setAdList(adlists.data)  
            }).catch((err, res) => {
                console.log(err)
            })
        } catch(err) {
           console.log(err)
        } 
    }, [])

    return (
        <>    
        <Layout>    
            <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Ad Generations - Today's Ads - {moment().format('DD MMM YYYY')}</h1>             
                    </div>                             
                    {/* ---- Content Start ---- */}                   
                    <div className="grid grid-cols-1 gap-1">
                            {/* ---- Content Start ---- */}
                            <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <table className="min-w-full table-fixed">
                                <thead>
                                    <tr>
                                        <th
                                            className="w-15 px-6 py-3 text-sm font-semibold leading-4 text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                            Stock No</th>
                                        <th
                                            className="w-15 px-6 py-3 text-sm font-semibold leading-4 text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                            Car Name</th>           
                                        <th
                                            className="w-70 px-6 py-3 text-sm font-semibold leading-4 text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                            Social Media Post</th>    
                                        <th
                                            className="w-70 px-6 py-3 text-sm font-semibold leading-4 text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                            Edit Post</th>          
                                    </tr>
                                </thead>

                                <tbody className="bg-white">                                   
                                    {adList && 
                                    [...adList]
                                    .filter(s=>moment(new Date(s.createdate)).format('DD-YYYY-MM')===moment(new Date()).format('DD-YYYY-MM'))
                                    .map((item, index) => (
                                        <tr key={index}>  
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div className="text-sm leading-5 text-gray-500"> {item.carstockno}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div className="text-sm leading-5 text-gray-500"> {item.carname}</div>
                                        </td>     
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            {/* Accordion */}                                                                     
                                            <Link to={`/adformlisttodayright?id=${item.id}&st=${item.carstockno}&car=${item.carname}&price=${item.carprice}`}>  
                                            <div className="text-sm underline text-bold text-red-500 mb-0">View Post</div>
                                            </Link>
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">                                                                  
                                            <Link to={`/adformedittoday?id=${item.id}&st=${item.carstockno}&car=${item.carname}`}>  
                                            <div className="text-sm underline text-bold text-green-500 mb-0">Edit Post</div>
                                            </Link>
                                            <div className="text-sm leading-5 text-gray-500">{item.carwebsitepin ? '' : '(Update Car Link)'}</div>
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* ---- Content End ---- */}                     
                       
                    </div>
                   {/* ---- Content End ---- */}
                </div>
            </div>    
        </Layout>
        </>
    );
}
    
export default AdFormListToday