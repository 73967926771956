import React,{useEffect} from 'react'
import Layout from './Layout'
import moment from 'moment';
import axios from 'axios'
import { serverAPI ,teamType,emirate} from '../utils/envConfig'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const Home = ({}) => {  
    //Get tomorrow date
    let dt = new Date();
    dt.setDate(dt.getDate() + 1)
    const tomorrow = moment(dt).format('DD-YYYY-MM')
    useEffect( async() => {
        try {
            const token =JSON.parse(localStorage.getItem("authuser")).access_token
            const headers = { 
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${token}`,
            };
         const res = await axios.get(`${serverAPI}/purchaseSchedule`,{headers})
         .then(members => {  
            let filData = members.data.filter(s=> moment(new Date(s.schDate)).format('DD-YYYY-MM')===tomorrow)
            if(!filData.length){
                toast.error('Please assign shifts for tomorrow', {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    });
            }
            else{
            let appData = members.data.filter(s=> moment(new Date(s.schDate)).format('DD-YYYY-MM')===tomorrow &&s.purchaseType===teamType[1])
            if(!appData.length){
                toast.error('Please assign shifts for App', {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    });
            }
            let dealData = members.data.filter(s=> moment(new Date(s.schDate)).format('DD-YYYY-MM')===tomorrow &&s.purchaseType===teamType[0] &&s.emirate===emirate[0])
            if(!dealData.length){
                toast.error('Please assign shifts for Dealership - ABU DHABI ', {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    });
            }
            let dealData2 = members.data.filter(s=> moment(new Date(s.schDate)).format('DD-YYYY-MM')===tomorrow &&s.purchaseType===teamType[0] &&s.emirate===emirate[1])
            if(!dealData2.length){
                toast.error('Please assign shifts for Dealership - Other Emirates', {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    });
            }
            }
         }).catch((err, res) => {
            //  console.log(err)
         })
        } catch(err) {
            console.log(err)
        }
    }, [])
    return (
        <>        
        <Layout />           
        </>
    );
}

export default Home
