import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { SalesTeamContext } from '../../contexts/SalesTeamContext';
import { activeStatus } from '../../utils/envConfig';
import Layout from '../Layout'

const ListSalesTeam = ({}) => { 
    const { salesTeam } = useContext(SalesTeamContext);
    // console.log(salesTeam)
   
    return (
        <> 
            <Layout>               
            <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Sales Team Members</h1>              
                    </div>                             
                   {/* ---- Content Start ---- */}                      
                    <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">                                   
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Name</th>
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Phone</th>
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Email</th>
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Status</th>                                   
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Edit</th>
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Delete</th>
                                </tr>
                            </thead>

                            <tbody className="bg-white">                                   
                                {salesTeam && [...salesTeam].map((item, index) => (
                                    <tr key={index}>      
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {item.name}</div>
                                    </td>  
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {item.phone}</div>
                                    </td>  
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {item.email}</div>
                                    </td>  
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> 
                                        {item.status && item.status ? activeStatus[0] : activeStatus[1]}
                                        </div>
                                    </td>  
                                       
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> 
                                        <Link to={`/editsalesteam?id=${item.id}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-blue-400"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                        </Link>
                                        </div>
                                    </td>    
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> 
                                        <Link to={`/removesalesteam?id=${item.id}`}>                                       
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-red-400"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                        </Link>                                       
                                        </div>
                                    </td>                                      
                                    </tr>
                                ))} 
                            </tbody>
                        </table>
                    </div>
                    {/* ---- Content End ---- */}
                </div>
            </div>   
            </Layout>     
        </>
    );
}
    
export default ListSalesTeam