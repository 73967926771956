import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from '../Layout'
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig';
import { activeStatus, teamType } from '../../utils/envConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const AddUser = ({}) => { 
    let navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');

    const validate =(firstName, lastName, email, password, cpassword)  => {
        if(firstName.length < 2)  {
            toast.error("Enter valid FirstName")
            return false
        }

        if(lastName.length < 2)  {
            toast.error("Enter valid LastName")
            return false
        }

        if(email.length < 5)  {
            toast.error("Enter valid Email")
            return false
        }

        if(password.length < 5)  {
            toast.error("Password should be greater than 5 characters")
            return false
        }
        if(cpassword !== password)  {
            toast.error("Password doesn't match")
            return false
        }
        else {
            return true
        }
    }

    // Add Admin User Info in DB
    const addMember = async (e) => {   
        e.preventDefault()
        if(validate(firstName, lastName, email, password, cpassword)){    
            const formData ={           
                first_name: firstName,
                last_name: lastName,
                email,
                password,
            }
            // console.log(formData)
            try{
                const token =JSON.parse(localStorage.getItem("authuser")).access_token
                const headers = { 
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`,
                };
                const res = await axios.post(`${serverAPI}/users`, formData,{headers})
                .then(user => {                        
                    navigate('/listuser')      
                }).catch((err) => {
                    if ((err.response.data.message).includes('duplicate key')) {                // DONT CHANGE MESSAGE, THE SAME REFERED FROM BACKEND
                        toast.error("User not added. Email already exist !")
                    } else {
                        toast.error("User not added !")
                    }                    
                }) 
            }catch(err){
                console.log(err)
                toast.error("Server error. Try Again !")
            }
        }   
    } 

    return (
        <>               
        <Layout>               
            <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Add Admin User</h1>              
                    </div>                             
                    {/* ---- Content Start ---- */}                             
                    <div className="inline-block min-w-full pt-2 pr-20 pl-20 pb-2 overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">               
                        <form name='member'
                        onSubmit={e => addMember(e)}>                                 
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th>                                       
                                    </th>  
                                    <th>
                                    </th>    
                                </tr>
                            </thead>
                            <tbody className="bg-white"> 
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">First Name </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <input className="bg-gray-200  border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="firstName" name="firstName" autoComplete="on" 
                                        required 
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Last Name </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <input className="bg-gray-200  border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="lastName" name="lastName" autoComplete="on" 
                                        required 
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Email </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <input className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="email" name="email" autoComplete="on" 
                                        required 
                                        placeholder="abc@abc.com"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Password </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <input className="bg-gray-200  border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="password" name="password" autoComplete="off" 
                                        required 
                                        minLength="5"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        />
                                    </td>
                                </tr> 
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Retype Password </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <input className="bg-gray-200  border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="password" name="cpassword" autoComplete="off" 
                                        required 
                                        value={cpassword}
                                        onChange={e => setCPassword(e.target.value)}
                                        />
                                    </td>
                                </tr> 
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <button type="submit" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                        >
                                        <span className="text-white font-sm">Add New User</span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>                           
                        </table>
                        </form>
                    </div>
                    {/* ---- Content End ---- */}                
                </div>
            </div>    
        </Layout>
        </>
    );
}
    
export default AddUser   