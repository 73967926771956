import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { serverAPI, adSocial } from "../../../utils/envConfig";
// import Layout from '../Layout'
import AdTwitter from "./AdTwitter";
import AdPinterest from "./AdPinterest";
import AdTumblr from "./AdTumblr";
import AdFacebook from "./AdFacebook";
import AdInstagram from "./AdInstagram";
import AdGoogle from "./AdGoogle";
import AdWebsite from "./AdWebsite";
import AdDubizzle from "./AdDubizzle";
import AdDubicars from "./AdDubicars";
import AdShops from "./AdShops";
import DubicarsAd from "./DubicarsAd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const AdSocialAll = ({ id, sid }) => {
  let navigate = useNavigate();
  const [adSId, setAdSId] = useState("");
  const [adData, setAdData] = useState("");
  const [emitxt, setEmiTxt] = useState("");
  const [refId, setRefId] = useState("");
  const [loading, setLoading] = useState(true);

  const sID = sid;
  const adID = id;

  useEffect(async () => {
    setAdSId(sID);
    try {
      const token = JSON.parse(localStorage.getItem("authuser")).access_token;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const res = await axios
        .get(`${serverAPI}/adGen/${adID}`, { headers })
        .then((ad) => {
          let reference = `REF : ${ad.data.carstockno}AC`;
          setRefId(reference);
          let emitext = "";

          const yearNum = +ad?.data?.caryear

          console.log(yearNum)

          if (yearNum <= 2015) {
              emitext = " through bank finance for 3 years";
          }
          // else if(ad.data.caryear === '2013') {
          //     emitext =' through bank finance for 3 years'
          // }
          else if (yearNum === 2016) {
              emitext = " through bank finance for 4 years";
          } else {
              emitext = " through bank finance";
          }
          ad.data.carprice = ad.data.carprice
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          ad.data.caremi = ad.data.caremi
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          ad.data.carodometer = ad.data.carodometer
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          setAdData(ad.data);
          setEmiTxt(emitext);
          setLoading(false);
        })
        .catch((err, res) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
    // }
  }, []);

  return (
    <>
      {/* ---- Content Start ---- */}
      {!loading && adData && emitxt ? (
        <div>
          {adSId && adSId === adSocial[0] && (
            <AdWebsite postData={adData} emiyear={emitxt} refId={refId} />
          )}
          {adSId && adSId === adSocial[1] && (
            <AdFacebook postData={adData} emiyear={emitxt} refId={refId} />
          )}
          {adSId && adSId === adSocial[2] && (
            <AdInstagram postData={adData} emiyear={emitxt} refId={refId} />
          )}
          {adSId && adSId === adSocial[4] && (
            <AdTwitter postData={adData} emiyear={emitxt} refId={refId} />
          )}
          {adSId && adSId === adSocial[5] && (
            <AdGoogle postData={adData} emiyear={emitxt} refId={refId} />
          )}
          {adSId && adSId === adSocial[6] && (
            <AdPinterest postData={adData} refId={refId} />
          )}
          {adSId && adSId === adSocial[7] && (
            <AdTumblr postData={adData} refId={refId} />
          )}
          {adSId && adSId === adSocial[8] && (
            <DubicarsAd postData={adData} emiyear={emitxt} refId={refId} />
          )}
          {adSId && adSId === adSocial[9] && (
            <AdDubicars postData={adData} emiyear={emitxt} />
          )}
          {adSId && adSId === adSocial[10] && (
            <AdDubizzle postData={adData} emiyear={emitxt} />
          )}
          {adSId && adSId === adSocial[11] && <AdShops postData={adData} />}
        </div>
      ) : (
        <div>There is no Ad Information</div>
      )}
      {/* ---- Content End ---- */}
    </>
  );
};

export default AdSocialAll;
