import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios'
import { serverAPI } from '../utils/envConfig'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const ResetPassword = ({}) => {
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();   

    const [authuser, setAuthuser] = useState('')
    const [userId, setUserId] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [cpassword, setCPassword] = useState('')

    useEffect( async() => {
        if (searchParams.has("email")) {
            const emailId = searchParams.get("email");  
            const inputData ={           
                email: emailId
                }
            // console.log(inputData)
            try{
                const res = await axios.post(`${serverAPI}/reset/password`, inputData)
                .then(user => {     
                    // console.log(user.data._id, user.data.email)      
                    setUserId(user.data._id)
                    setEmail(user.data.email)
                }).catch((err) => {       
                    toast.error("User not available !") 
                }) 
            }catch(err){
                console.log(err)
                toast.error("Server error. Try Again !")
            }
        }
    }, []);

    const validate =(password, cpassword)  => {
        if(password.length < 5)  {
            toast.error("Password should be greater than 5 characters")
            return false
        }
        if(cpassword !== password)  {
            toast.error("Password doesn't match")
            return false
        }
        else {
            return true
        }
    }

  	const submitReset = async (e) => {
        e.preventDefault()

        if(validate(password, cpassword)){    
            const inputData ={           
                password,
                }
            try{
                const res = await axios.patch(`${serverAPI}/reset/password/${userId}`, inputData)
                .then(user => {       
                    // console.log(user)    
                // navigate('/')
                }).catch((err) => {       
                    toast.error("User not available !") 
                }) 
            }catch(err){
                console.log(err)
            }
        }
    }

    return (	
        <>
        <div className="flex flex-col  bg-no-repeat bg-cover bg-hero  h-screen justify-center items-center overflow-hidden" >
        <div className="flex flex-center flex-col p-10 md:pb-10 lg:pb-10 justify-center items-center">
            <a href="/" className="m-6 sm:mb-2 align-middle">
            <img alt="Logo" src="./img/logo-demo7.svg" className="h-12 md:h-16 lg:h-16"/>
            </a>
            <div className="bg-white rounded shadow-sm p-8 md:p-5 lg:p-8 mx-auto block">
                <form className="form sm:w-full leading-normal" noValidate="novalidate" id="kt_sign_in_form" action="#" > 
                    <div className="text-center mb-2 lg:mb-6 md:mb-4">
                        <h1 className="mb-3 font-semibold text-lg lg:font-xl md:text-lg">Reset Password</h1>                       
                        <label className="text-xsmall  text-bold leading-normal"> {email} </label>
                    </div>                   
                    <div className="mb-2 lg:mb-6 md:mb-4">                       
                        <label className="text-xsmall  text-bold leading-normal">New Password </label>
                        <input className="bg-[#F5F8FA] border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1" 
                        type="password" name="password" autoComplete="off"
                        required
                        onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="mb-2 lg:mb-6 md:mb-4">                       
                        <label className="text-xsmall  text-bold leading-normal">Confirm Password </label>
                        <input className="bg-[#F5F8FA] border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1" 
                        type="password" name="cpassword" autoComplete="off"
                        required
                        onChange={e => setCPassword(e.target.value)}
                        />
                    </div>
                    <div className="mb-2 lg:mb-6 md:mb-4">
                        <button type="submit" id="kt_sign_in_submit" className="group relative w-full flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
                        onClick={e=>submitReset(e)}
                        >
                        <span className="text-white font-sm">Reset</span>
                        </button>                       
                    </div>  
                    <div className="text-center mb-2 lg:mb-6 md:mb-4">
                        <Link to={'/'}>
                        <label className="text-xsmall text-bold mb-0">Login</label>
                        </Link>
                    </div>                                    
                </form>
            </div>
        </div>
        <div className="flex flex-center  p-10">
            <div className="flex items-center font-bold">
                <a href="https://albacars.ae" className="text-[#A1A5B7] text-xsmall px-2">About</a>
                <a href="mailto:marketing@albacars.ae" className="text-[#A1A5B7] text-xsmall">Contact</a>
            </div>
        </div>
        </div>
        </>
    )
}

export default ResetPassword