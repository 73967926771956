import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClipboardCopy from "./ClipboardCopy";
import DownloadText from "./DownloadText";

const AdWebsite = ({ postData, emiyear, refId }) => {
  let offers = "";
  let warranty = "";
  let service = "";
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [txt, setTxt] = useState(``);
  if (postData.offers !== "") {
    offers = `
${postData.offers}
————————`;
  }
  if (postData.carwarranty !== "") {
    warranty = `
Warranty : ${postData.carwarranty}`;
  }
  if (postData.carservice !== "") {
    service = `
Service: ${postData.carservice}`;
  }

  useEffect(async () => {
    // Website Ad Post Template
    setTxt(
      `${postData.carname}
————————
${postData.caryear}
————————
AED ${postData.carprice} (cash) or
(AED ${postData.caremi} per month with 0% downpayment${emiyear})
————————${offers}
Odometer : ${postData.carodometer} Kms${warranty}${service}
Specification : ${postData.carspec}
————————
Cylinders : ${postData.carcylinder}
Wheel Size : ${postData.carwheel}
————————
DESCRIPTION: 
————————
${postData.description}
————————
OPTIONS:
————————
${postData.options}
————————
WEBSITE:
————————
${postData.carwebsite}
————————
Location:
————————
${postData.carlocationgoogle}
${postData.carshowroom}
————————
CASH BUYERS:
————————
${postData.cashbuyers}
————————
FINANCE BUYERS:
————————
${postData.financebuyers}
————————
BOOKING OPTIONS:
————————
${postData.booking}
————————
Additional Information:
————————
Price is ${postData.addexpenses}
————————
SELL YOUR CAR
————————
${postData.sellyourcar} 

${refId}`
    );
    setLoading(true);
  }, []);

  return (
    <>
      {loading ? (
        <div>
          <div className="w-0 flex-1 flex items-center">
            <ClipboardCopy copyText={txt} />
            <DownloadText downloadText={txt} fileName="Website.txt" />
          </div>
          <textarea
            id="offers"
            name="offers"
            value={txt}
            readOnly
            rows={30}
            className="shadow-sm pl-2 pt-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
          />
        </div>
      ) : (
        <div>Loading !!!</div>
      )}
    </>
  );
};

export default AdWebsite;
