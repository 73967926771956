export default function AdFormControl({ handleClick, currentStep, steps }) {
  return (
    <div className="container mt-4 mb-8 flex justify-around">
      <button
        onClick={() => handleClick()}
        className={`cursor-pointer rounded-xl border border-slate-300 bg-[#F25757] py-2 px-4 font-medium text-white transition duration-200 ease-in-out hover:bg-red-700 focus:outline-none  
        ${
          currentStep === 1 ? " cursor-not-allowed opacity-50 " : ""
        }`}
      >
        Back
      </button>

      <button
        onClick={() => handleClick("next")}
        id="kt_sign_in_submit" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
      >
        <span className="text-white font-sm">
        {currentStep === steps.length - 1 ? "Confirm" : "Next"}
        </span>
      </button>
    </div>
  );
}