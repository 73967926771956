import React, { useState, useEffect } from "react";
import axios from "axios";
import { serverAPI } from "../../utils/envConfig";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const ExportAppDU = ({}) => {
  const [dataDUApp, setDataDUApp] = useState([]);
  const [isDataDownloading, setIsDataDownloading] = useState(true);
  // const [enableExport, setEnableExport] = useState(false);

  //Set Filename
  let dt = new Date();
  dt.setDate(dt.getDate() - 1);
  const fileYesterday = moment(dt).format("YYYY_MM_DD");

  // Yesterday date as filename
  const fileNameDUApp = `FIL_APP_${fileYesterday}`; // Dubbizle- App

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  useEffect(async () => {
    setIsDataDownloading(true);
    const token = JSON.parse(localStorage.getItem("authuser")).access_token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axios
      .get(`${serverAPI}/filter/duapp`, { headers })
      .then((users) => {
        let newArray = [];
        users.data.map((item) => {
          // item.phone = item.phone.substring(1);
          // Extracting only required columns
          let obj = {
            "EXPECTED PRICE (AED)": item.expectedPrice,
            YEAR: item.year,
            "MILEAGE (Kms)": item.mileage,
            COLOR: item.color,
            SPECS: item.specs,
            "BODY TYPE": item.bodyType,
            Notes: item.extras,
            "MOBILE ( Contact Number)": item.phone,
            "B R AND": item.brand,
            MODEL: item.model,
            EMIRATE: item.emirate,
            "Customer Name": item.customerName,
            "LEAD STATUS": item.leadStatus,
            "Lead Source": item.leadSource,
            "HOT LEAD ?": item.hotLead,
            "Reference Number ": item.refNumber,
            "Whatsapp Link": item.whatsappLink,
            "SELL LEAD OWNER": item.sellLeadOwner,
            "PURCHASER MOBILE NUMBER": item.agentNumber,
            'AD LINK (URL)': item.url,
          };
          newArray.push(obj);
          setDataDUApp(newArray);
          setIsDataDownloading(false);
        });
      })
      .catch((err, res) => {
        console.log(err);
        setIsDataDownloading(false);
      });
  }, []);

  const exportToCSVDUApp = (apiData, fileName) => {
    if (apiData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(apiData);
      for (let i = 1; i < apiData.length + 1; i++) {
        ws[
          XLSX.utils.encode_cell({
            c: 16,
            r: i,
          })
        ].l = { Target: apiData[i - 1]["Whatsapp Link"] };
      }
      const wb = { Sheets: { dataDUApp: ws }, SheetNames: ["dataDUApp"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dataDUApp = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(dataDUApp, fileName + fileExtension);
      toast.success("Downloading Dubbizle App data !");
    } else {
      console.log("No data");
      toast.error("Dubbizle App data not available. Try Again !");
    }
  };

  // Enable/Disable "Export to Excel" button
  let starthours = 11;
  let endhours = 23;
  let curhours = dt.getHours();

  // // Enable - Current hour match start hour
  // const enableLive = async() => {
  // console.log('Enable Export')
  // setEnableExport(true)
  // }

  // let DiffEnable = starthours - curhours

  // if(DiffEnable === 0) {
  //     console.log('Enable trigger')
  //     setTimeout(enableLive, DiffEnable); // trigger once
  // }

  // // Disable - Current hour match start hour
  // const disableLive = async() => {
  //     console.log('Disable Export')
  //     setEnableExport(false)
  // }

  // let DiffDisable = endhours - curhours

  // if(DiffDisable === 0) {
  //     console.log('Disable trigger')
  //     setTimeout(disableLive, DiffDisable); // trigger once
  // }

  return (
    <>
      <form
        className="form sm:w-full leading-normal mt-10"
        noValidate="novalidate"
        id="kt_sign_in_form"
        action="#"
      >
        <br />
        <span className="text-gray font-sm font-bold">App</span> (Filename:{" "}
        {fileNameDUApp}.csv)
        {/* {enableExport && enableExport
                ?                 
                    <button 
                    type="submit" id="kt_sign_in_submit"                   
                    className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                                       
                    onClick={(e) => exportToCSVDUApp(dataDUApp, fileNameDUApp)}
                    >
                    <span className="text-white font-sm">Export to Excel</span>
                    </button>
                :                  
                    <button 
                    disabled 
                    type="submit" id="kt_sign_in_submit"                   
                    className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                                       
                    onClick={(e) => exportToCSVDUApp(dataDUApp, fileNameDUApp)}
                    >
                    <span className="text-white font-sm">Export to Excel</span>
                    </button>
                } */}
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1 disabled:bg-red-900"
          disabled={isDataDownloading}
          onClick={(e) => exportToCSVDUApp(dataDUApp, fileNameDUApp)}
        >
          <span className="text-white font-sm">{isDataDownloading ? "Downloading..." : "Export to Excel"}</span>
        </button>
      </form>
    </>
  );
};

export default ExportAppDU;
