import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig'
import Layout from '../Layout';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const downloadCSV = async (e, fileName) => {
    e.preventDefault()
    // Get signed url from s3 
    try {
        const uploadData = JSON.stringify(
            {
                "fileName": fileName
            }
        );
        // console.log(uploadData)
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization' : `Bearer ${loggedInUser.token}`,
        };
        // Upload to s3 using signed url
        const resp = await axios.post(`${serverAPI}/filter/csvMissingCarsDownload`, uploadData, { headers });
        const url = resp.data.signedRequest
        if (url) {
            try {
                window.location.href = url
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        }
    } catch (err) {
        // Handle Error Here
        console.error(err);
    }

}

const downloadCSV2 = async (e, fileName) => {
    e.preventDefault()
    // Get signed url from s3 
    try {
        const uploadData = JSON.stringify(
            {
                "fileName": fileName
            }
        );
        // console.log(uploadData)
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization' : `Bearer ${loggedInUser.token}`,
        };
        // Upload to s3 using signed url
        const resp = await axios.post(`${serverAPI}/filter/csvPriceChangeCarsDownload`, uploadData, { headers });
        const url = resp.data.signedRequest
        if (url) {
            try {
                window.location.href = url
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        }
    } catch (err) {
        // Handle Error Here
        console.error(err);
    }

}
const ListOtherFinal = ({ }) => {
    const [listMissingCars, setListMissingCars] = useState([]);
    const [listPriceChangeCars, setListPriceChangeCars] = useState([]);

    useEffect(() => {
        async function getDealList() {
            const token = JSON.parse(localStorage.getItem("authuser")).access_token
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            };
            const res = await axios.get(`${serverAPI}/filter/csvMissingCarsList`, { headers })
                .then(files => {
                    const valueToRemove = 'MISSING_CARS/'
                    const data = files.data.filter(item => item !== valueToRemove)
                    setListMissingCars(data);
                }).catch((err, res) => {
                    console.log(err)
                })
        }
        async function getPriceChangeList() {
            const token = JSON.parse(localStorage.getItem("authuser")).access_token
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            };
            const res = await axios.get(`${serverAPI}/filter/csvPriceChangeCarsList`, { headers })
                .then(files => {
                    const valueToRemove = 'PRICE_CHANGE_CARS/'
                    const data = files.data.filter(item => item !== valueToRemove)
                    setListPriceChangeCars(data);
                }).catch((err, res) => {
                    console.log(err)
                }) 
        }
        getDealList();
        getPriceChangeList();

    }, []);



    return (
        <>
            <Layout>
                <div className="w-full px-4 py-2 bg-gray-200 lg:w-full">
                    <div className="container mx-auto mt-8">                     
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 ' >
                                <div className='bg-gray-50  border-gray-200  shadow-md rounded-md'>
                                    <p className="px-6 py-3 text-xs font-extrabold leading-4 tracking-wider text-left text-gray-500 uppercase ">
                                        Download Missing Cars Files</p>
                                    {listMissingCars.length>0 && [...listMissingCars].map((item, index) => (
                                        <div key={index}>
                                            <div className="px-6 py-4 whitespace-no-wrap  border-gray-200">
                                                <div className="text-sm leading-5 text-gray-500">
                                                    <button className="group relative flex justify-center py-2 border bg-gray-100  px-4 hover:bg-gray-300 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1" onClick={(e) => { downloadCSV(e, item) }}>{item.split("/")[1]}</button></div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                                <div className='bg-gray-50  border-gray-200  shadow-md rounded-md'>
                                    <p className="px-6 py-3 text-xs font-extrabold leading-4 tracking-wider text-left text-gray-500 uppercase ">
                                        Download Price Changed Cars Files</p>
                                    {listPriceChangeCars.length>0 && [...listPriceChangeCars].map((item, index) => (
                                        <div key={index}>
                                            <div className="px-6 py-4 whitespace-no-wrap  border-gray-200">
                                                <div className="text-sm leading-5 text-gray-500">
                                                    <button className="group relative flex justify-center py-2 border bg-gray-100  px-4 hover:bg-gray-300 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1" onClick={(e) => { downloadCSV2(e, item) }}>{item.split("/")[1]}</button></div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            </div>                     
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default ListOtherFinal
