import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { activeStatus, roles } from '../../utils/envConfig';
import Layout from '../Layout'
import axios from 'axios'
import { serverAPI } from '../../utils/envConfig'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const ListUser = ({}) => { 
    let navigate = useNavigate();
    const { users } = useContext(UserContext);

    const [showModal, setShowModal] = useState(false);
    // const [btnLoading, setBtnLoading] = useState(false);
    const [userId, setuserId] = useState('');

    const handleModal= (e, id)=>{
        e.preventDefault()          
        setuserId(id)
        setShowModal(true)
    }

    const url = '/listuser';
    const submitDel = async (e) => {       
        e.preventDefault()
        // setBtnLoading(true)
        // console.log(userId)
        try{
            const token =JSON.parse(localStorage.getItem("authuser")).access_token
            const headers = { 
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${token}`,
            };
            const res = await axios.delete(`${serverAPI}/users/${userId}`, {headers})
            .then(user => {                    
                // navigate('/listuser')     
                window.location.href=url
            }).catch((err) => {
                toast.error("User not removed !")                                  
            }) 
        }catch(err){
            console.log(err)
            toast.error("Server error. Try Again !")
        }
        // setBtnLoading(false)
        setShowModal(false)
        
    }
   
    return (
        <>  
            <Layout>               
                <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                    <div className="container mx-auto">  
                        <div>
                            <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Admin Users</h1>              
                        </div>                             
                    {/* ---- Content Start ---- */}    
                                                      
                        <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">                                   
                            <table className="min-w-full">
                                <thead>
                                    <tr>
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            First Name</th>
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Last Name</th>
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Email</th>
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Status</th>
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Role</th>
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Edit</th>
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Delete</th>
                                    </tr>
                                </thead>

                                <tbody className="bg-white">                                   
                                    {users && [...users].map((item, index) => (
                                        <tr key={index}>      
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div className="text-sm leading-5 text-gray-500"> {item.first_name}</div>
                                        </td>  
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div className="text-sm leading-5 text-gray-500"> {item.last_name}</div>
                                        </td>  
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div className="text-sm leading-5 text-gray-500"> {item.email}</div>
                                        </td>  
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div className="text-sm leading-5 text-gray-500"> 
                                            {item.active && item.active ? activeStatus[0] : activeStatus[1]}
                                            </div>
                                        </td>  
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div className="text-sm leading-5 text-gray-500"> 
                                            {item.role}
                                            </div>
                                        </td>    
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div className="text-sm leading-5 text-gray-500"> 
                                            <Link to={`/editUser?id=${item.id}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-blue-400"
                                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                            </svg>
                                            </Link>
                                            </div>
                                        </td>    
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div className="text-sm leading-5 text-gray-500">   
                                            {item.role && (item.role === roles[0])  
                                            ?
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-red-400"
                                                fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                                                <path strokeLinecap="round" strokeLinejoin="round" 
                                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                            :                                    
                                            <Link to={''} onClick={(e) => handleModal(e, item.id)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-red-400"
                                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                            </Link>
                                            }
                                            </div>
                                        </td>                                      
                                        </tr>
                                    ))} 
                                </tbody>
                            </table>
                        </div>                
                    </div>
                </div>    
                {/* ****** Modal Start ****** */}
                {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            {/* <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            </div> */}
                            {/*body*/}
                            <div className="relative p-8 flex-auto">
                            <p className="my-4 text-blueGray-500 text-lg leading-relaxed">                 
                            Are you sure you want to delete the User? 
                            </p>  
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                            <button
                                className="bg-green-600 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowModal(false)}
                            >
                            No
                            </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => submitDel(e)}
                            >
                            Yes
                            {/* {btnLoading && (
                                    <svg role="status" className="inline mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                )} */}
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}  
                {/* ****** Modal End ****** */}

                {/* ---- Content End ---- */}
            </Layout> 
        </>
    );
}
    
export default ListUser