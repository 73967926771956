import React, { useEffect, useContext ,useState,Fragment} from 'react'
import 'tw-elements';
import axios from 'axios';
import { serverAPI } from '../utils/envConfig';
import { UserContext } from '../contexts/UserContext';
import { TeamContext } from '../contexts/TeamContext';
import { SalesTeamContext } from '../contexts/SalesTeamContext';
import { Dialog, Transition } from '@headlessui/react'
import { XIcon} from '@heroicons/react/outline'
import Nav from './NavBar'
const Navbar = () => {   
    // Global Contexts 
    const { users, setUsers } = useContext(UserContext);
    const { purchaseTeam, setPurchaseTeam } = useContext(TeamContext);
    const { salesTeam, setSalesTeam } = useContext(SalesTeamContext);

    // Authentication and Authorization
    const token =JSON.parse(localStorage.getItem("authuser")).access_token
    const myRole=JSON.parse(localStorage.getItem("authuser")).role
    const [sidebarOpen, setSidebarOpen] = useState(false)

    useEffect( async() => {    
       // Admin Users Context
       try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const res = await axios.get(`${serverAPI}/users`,{headers})
            .then(users => {             
                setUsers(users.data);     
            }).catch((err, res) => {
                console.log(err)
            })
        } catch(err) {
            console.log(err)
        }

        // Purchase Team Members Context
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const res = await axios.get(`${serverAPI}/purchaseTeam`,{headers})
            .then(members => {             
                setPurchaseTeam(members.data);     
            }).catch((err, res) => {
                console.log(err)
            })
        } catch(err) {
            console.log(err)
        }

        // Sales Team Members Context
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const res = await axios.get(`${serverAPI}/salesTeam`,{headers})
            .then(members => {             
                setSalesTeam(members.data);     
            }).catch((err, res) => {
                console.log(err)
            })
        } catch(err) {
            console.log(err)
        }
    }, [])

    return (
    <>
        <div className="px-4 py-2 bg-gray-200 bg-[#F25757] lg:w-1/6"> 
        <button onClick={() => setSidebarOpen(!sidebarOpen)} >      
            <svg xmlns="http://www.w3.org/2000/svg" className="inline w-8 h-8 text-white lg:hidden" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>    
            </button>
            <div className="lg:block hidden">                         
                <Nav/>               
            </div>
            {/* Mobile Navbar start */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                    >
                    <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-[#F25757]">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                            <button
                            type="button"
                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            onClick={() => setSidebarOpen(false)}
                            >
                            <span className="sr-only">Close sidebar</span>
                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </button>
                        </div>
                        </Transition.Child>
                
                        <div className="px-4 py-2 bg-gray-200 bg-[#F25757] lg:w-1/6 h-screen">       
                            <div className="block">  
                                <Nav/>
                            </div>
                        </div>
                    
                    </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                    {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>
            {/* Mobile Navbar end */}
        </div>
    </>
    );
}

export default Navbar

