import React from 'react'
import Layout from './Layout';
import ExportDealDU from './exports/ExportDealDU';
import ExportAppDU from './exports/ExportAppDU';
import ExportFreshAppKF from './exports/ExportFreshAppKF';
import ExportReFreshAppKF from './exports/ExportReFreshAppKF';
import ExportFreshDealKF from './exports/ExportFreshDealKF';
import ExportReFreshDealKF from './exports/ExportReFreshDealKF';

const Exports = ({}) => {   
    return (
        <>    
        <Layout>            
            <div className="w-full px-4 py-2 bg-gray-200 lg:w-full">               
                <ExportDealDU />
                <ExportAppDU />    
                {/* <ExportFreshAppKF/>   
                <ExportReFreshAppKF/>  
                <ExportFreshDealKF/>   
                <ExportReFreshDealKF/> */}
            </div>  
        </Layout>
        </>
    );
}
    
export default Exports
    