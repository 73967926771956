import React, {useEffect, useState} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../Layout'
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig';
import { activeStatus, roles } from '../../utils/envConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const EditUser = ({}) => { 
    let navigate = useNavigate();
    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState(null);
    const [role, setRole] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();    
    const [user, setUser] = useState({});
    const [userId, setUserId] = useState('');

    useEffect( async() => {
        if (searchParams.has("id")) {
            const memID = searchParams.get("id");           
            try{
                const token =JSON.parse(localStorage.getItem("authuser")).access_token
                const headers = { 
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`,
                };
                const res = await axios.get(`${serverAPI}/users/${memID}`,{headers})
                .then(user => {                
                    setUser(user.data)
                    setUserId(user.data.id)
                    setFirstName(user.data.first_name)
                    setLastName(user.data.last_name)
                    setEmail(user.data.email)
                    setStatus(user.data.active)
                    setRole(user.data.role)
                }).catch((err) => {       
                    toast.error("User not available !") 
                }) 
            }catch(err){
                console.log(err)
                toast.error("Server error. Try Again !")
            }
        }
    }, []);

    const validate =(firstName,lastName,status,role)  => {
        if(firstName.length < 2)  {
            toast.error("Enter valid FirstName")
            return false
        }

        if(lastName.length < 2)  {
            toast.error("Enter valid LastName")
            return false
        }

        if(status === 'Select')  {
            toast.error("Select the Status")
            return false
        } 
        if(role === 'Select')  {
            toast.error("Select the Role")
            return false
        }else {
            return true
        }     
    }

    // Edit Purchase Team member Info in DB
    const editMember = async (e) => {   
        e.preventDefault()
        if(validate(firstName,lastName,status,role)){  
            const formData ={           
            firstName,
            lastName,
            status,
            role,
            }

            try{
                // console.log(userId, formData)
                const token =JSON.parse(localStorage.getItem("authuser")).access_token
                const headers = { 
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`,
                };
                const res = await axios.patch(`${serverAPI}/users/${userId}`, formData,{headers})
                .then(user => {    
                    // console.log(user.data)                
                    navigate('/listuser')      
                }).catch((err) => {                    
                    toast.error("User not modified with changes !")               
                }) 
            }catch(err){
                console.log(err)
                toast.error("Server error. Try Again !")
            }
        }   
    } 

    return (
        <>      
        <Layout>               
            <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Edit Admin User</h1>              
                    </div>                             
                    {/* ---- Content Start ---- */}                                  
                    <div className="inline-block min-w-full pt-2 pr-20 pl-20 pb-2 overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">               
                        <form name='member'
                        onSubmit={e => editMember(e)}>                                 
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th>                                       
                                    </th>  
                                    <th>
                                    </th>    
                                </tr>
                            </thead>
                            <tbody className="bg-white"> 
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">First Name </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <input className="bg-gray-200  border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="name" name="firstName" autoComplete="on" 
                                        required 
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Last Name </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <input className="bg-gray-200  border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="name" name="lastName" autoComplete="on" 
                                        required 
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Email </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">                                       
                                        <label 
                                         className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "                            
                                        >{email} </label>
                                        &nbsp; (ReadOnly)
                                    </td>
                                </tr> 
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Status </label>                                             
                                    </td>
                                    {role && (role === roles[0]) 
                                    ?
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">{status && status ? activeStatus[0] : activeStatus[1]} </label>                                             
                                    </td>
                                    :
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"> 
                                        <select className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "
                                        onChange={e => setStatus(e.target.value)}
                                        name="status"
                                        value={status}
                                        >
                                            <option defaultValue>Select</option>                                                                                      
                                            <option value={true} >{activeStatus[0]} </option>
                                            <option value={false} >{activeStatus[1]} </option>
                                        </select>  &nbsp; &nbsp; &nbsp;    
                                    </td>
                                    }
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Role </label>                                             
                                    </td>
                                    {role && (role === roles[0]) 
                                    ?
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">{roles[0]} </label>                                             
                                    </td>
                                    :
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"> 
                                        <select className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "
                                        onChange={e => setRole(e.target.value)}
                                        name="role"
                                        value={role}
                                        >
                                            <option defaultValue>Select</option>                                                                                      
                                            <option value={roles[1]} >{roles[1]} </option>
                                            <option value={roles[2]} >{roles[2]} </option>
                                            <option value={roles[3]} >{roles[3]} </option>
                                        </select>  &nbsp; &nbsp; &nbsp;    
                                    </td>
                                    }
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <button type="button" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                        onClick={() => navigate(-1)}
                                        >
                                        <span className="text-white font-sm">Go Back</span>
                                        </button>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">                                       
                                        <button                                        
                                        type="submit" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                        >
                                        <span className="text-white font-sm">Edit Changes</span>
                                        </button>                                                                        
                                    </td>
                                </tr>
                            </tbody>                           
                        </table>
                        </form>
                    </div>
                    {/* ---- Content End ---- */}
                </div>
            </div>    
        </Layout>
        </>
    );
}
    
export default EditUser    