import React, {useEffect, useState} from 'react'

const DownloadText = ({downloadText, fileName}) => { 
  const  downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([downloadText],    
                {type: 'text/plain;charset=utf-8'});
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}`;
    document.body.appendChild(element);
    element.click();
  }

  return (  
    <>
      <button type="button" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
        onClick={downloadTxtFile}
        >
        <span className="text-white font-sm"> Download </span>
      </button>
    </>
  );
}

export default DownloadText