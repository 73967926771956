import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import ClipboardCopy from './ClipboardCopy';
import DownloadText from './DownloadText';

const AdTumblr = ({postData, refId}) => { 
    let navigate = useNavigate();

    // Tumblr Ad Post Template
    const txt = 
`${postData.description}

Buy used luxury cars in Dubai UAE at the best price from ALBA Cars showroom with warranty. 0% downpayment option, Pay monthly.
-----------------------------------------------
Please add hyperlinks as follows.

<a href="${postData.carwebsitepin}">Audi A7</a>
<a href="https://www.albacars.ae/buy-used-cars-uae">Buy used luxury cars in Dubai</a>

${refId}`

  return (  
    <>    
        <div className="w-0 flex-1 flex items-center">                           
            <ClipboardCopy copyText={txt} />
            <DownloadText downloadText={txt} fileName='Tumblr.txt' />
        </div>
        <textarea
            id="offers"
            name="offers"
            value={txt}
            readOnly 
            rows={10}
            className="shadow-sm pl-2 pt-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
        />
    </>
  );
}

export default AdTumblr