import React from "react";
import { useNavigate } from "react-router-dom";
import ClipboardCopy from "./ClipboardCopy";
import DownloadText from "./DownloadText";

const AdShops = ({ postData }) => {
  let navigate = useNavigate();

  // Pinterest Ad Post Template
  const txt = `${postData.carname} | ${postData.caryear} | ${postData.carodometer}Kms

`;

  return (
    <>
      <div className="w-0 flex-1 flex items-center">
        <ClipboardCopy copyText={txt} />
        <DownloadText downloadText={txt} fileName="Pinterest.txt" />
      </div>
      <textarea
        id="offers"
        name="offers"
        value={txt}
        readOnly
        rows={5}
        className="shadow-sm pl-2 pt-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
      />
    </>
  );
};

export default AdShops;
