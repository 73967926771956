import React, {useEffect, useState} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../Layout'
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const RemoveSalesTeam = ({}) => { 
    let navigate = useNavigate();    
   
    const [searchParams, setSearchParams] = useSearchParams();    
    const [memberId, setMemberId] = useState('');
    const [buttonDisable, setButtonDisable] = useState(true);

    useEffect( async() => {
        if (searchParams.has("id")) {
            const memID = searchParams.get("id");           
            try{
                const token =JSON.parse(localStorage.getItem("authuser")).access_token
                const headers = { 
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`,
                };
                const res = await axios.get(`${serverAPI}/salesTeam/validMember/${memID}`,{headers})
                .then(user => {            
                    setMemberId(user.data._id)                   
                }).catch((err) => {           
                    if (err.response.data.message === 'ASSIGNED') {                     // DONT CHANGE MESSAGE, THE SAME REFERED FROM BACKEND         
                        setButtonDisable(false)              
                        toast.error("Sales Member has been assigned. Remove from Ads first !")
                    } else {
                        toast.error("Sales Member not available !")     
                    }                      
                }) 
            }catch(err){
                console.log(err)
                toast.error("Server error. Try Again !")
            }
        }
    }, []);   



    // Delete Purchase Team member Info from DB
    const deleteMember = async (e) => {   
        e.preventDefault()        
        try{
            const token =JSON.parse(localStorage.getItem("authuser")).access_token
            const headers = { 
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${token}`,
            };
            await axios.delete(`${serverAPI}/salesTeam/${memberId}`,{headers})
            .then(() => {                     
                navigate('/listsalesteam')      
            }).catch((err) => {                    
                toast.error("Sales Team Member not removed !")               
            }) 
        }catch(err){
            console.log(err)
            toast.error("Server error. Try Again !")
        }           
    } 

    return (
        <>    
        <Layout>  
        <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Remove Sales Team Member</h1>              
                    </div>                             
                   {/* ---- Content Start ---- */}                            
                    <div className="inline-block min-w-full pt-2 pr-20 pl-20 pb-2 overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">               
                        <form name='member'
                        onSubmit={e => deleteMember(e)}
                        >                                 
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th>                                       
                                    </th>  
                                    <th>
                                    </th>    
                                </tr>
                            </thead>
                            <tbody className="bg-white"> 
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Are you sure you want to delete? </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                       
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <button type="button" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                        onClick={() => navigate(-1)}
                                        >
                                        <span className="text-white font-sm">Go Back</span>
                                        </button>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        {buttonDisable && buttonDisable
                                        ?
                                            <button                                        
                                            type="submit" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                            >
                                            <span className="text-white font-sm">Remove </span>
                                            </button>
                                        :
                                            <button                                        
                                            type="button" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                            disabled
                                            >
                                            <span className="text-white font-sm">Remove - Disabled</span>
                                            </button>
                                        }                                        
                                    </td>
                                </tr>
                            </tbody>                           
                        </table>
                        </form>
                    </div>
                    {/* ---- Content End ---- */}
                </div>
            </div>    
        </Layout>
        </>
    );
}
    
export default RemoveSalesTeam