import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClipboardCopy from "./ClipboardCopy";
import DownloadText from "./DownloadText";

const AdInstagram = ({ postData, emiyear, refId }) => {
  let emi = "";
  let offers = "";
  let warranty = "";
  let service = "";
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [txt, setTxt] = useState(``);
  if (emiyear !== " through bank finance") {
    emi = emiyear.replace(/\D/g, "");
    emi = `(${bold(emi) + " years".replace(/[A-Za-z]/g, translate)})⁣`;
  }
  if (postData.offers !== "") {
    offers = `
${postData.offers}
————————`;
  }
  if (postData.carwarranty !== "") {
    warranty = `
Warranty : ${postData.carwarranty}`;
  }
  if (postData.carservice !== "") {
    service = `
Service: ${postData.carservice}`;
  }
  function bold(number) {
    return number.replace(/\d/g, (c) =>
      String.fromCodePoint(0x1d79e + c.charCodeAt(0))
    );
  }
  function translate(char) {
    let diff;
    if (/[A-Z]/.test(char)) {
      diff = "𝗔".codePointAt(0) - "A".codePointAt(0);
    } else {
      diff = "𝗮".codePointAt(0) - "a".codePointAt(0);
    }
    return String.fromCodePoint(char.codePointAt(0) + diff);
  }

  useEffect(async () => {
    // Instagram Ad Post Template
    setTxt(
      `${postData.carname} ▪️ ${postData.caryear} ▪️ 𝗔𝗘𝗗 ${bold(
        postData.caremi
      )} 𝗣𝗲𝗿 𝗠𝗼𝗻𝘁𝗵 ${emi} 
-𝗔𝗘𝗗 ${bold(postData.carprice)} ▪️ 0% Downpayment- ▪️ ${postData.cartitle}
————————
Odometer : ${postData.carodometer} Kms${warranty}${service}
Specification : ${postData.carspec}
————————⁣⁣
𝐃𝐈𝐑𝐄𝐂𝐓 𝐂𝐀𝐋𝐋 / 𝐖𝐇𝐀𝐓𝐒𝐀𝐏𝐏` +
        `

📞: +971 4 377 2502
` +
        `————————⁣
Visit our showroom: Open from 10:00 AM - 10:00 PM everyday.
————————⁣
📋DESCRIPTION:⁣
————————⁣
${postData.description}
————————⁣
Additional Information:
————————
Price is ${postData.addexpenses}

${postData.carhashinsta}

${refId}`
    );
    setLoading(true);
  }, []);
  return (
    <>
      {loading ? (
        <div>
          <div className="w-0 flex-1 flex items-center">
            <ClipboardCopy copyText={txt} />
            <DownloadText downloadText={txt} fileName="Instagram.txt" />
          </div>
          <textarea
            id="offers"
            name="offers"
            value={txt}
            readOnly
            rows={30}
            className="shadow-sm pl-2 pt-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
          />
        </div>
      ) : (
        <div>Loading !!!</div>
      )}
    </>
  );
};

export default AdInstagram;
