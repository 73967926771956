// Set Environment
const environment =process.env.REACT_APP_ENV

// Server URL depending on environment
function getURL(environment){
    switch(environment) {
        case 'local':
             return process.env.REACT_APP_LOCAL_URL
         case 'prod':
            return process.env.REACT_APP_PROD_URL
        default:
             return process.env.REACT_APP_LOCAL_URL
}}
export const server=getURL(environment)

// API URL depending on environment
function getAPIURL(environment){
    switch(environment) {
        case 'local':
             return process.env.REACT_APP_LOCAL_API_URL
         case 'prod':
            return process.env.REACT_APP_PROD_API_URL
        default:
             return process.env.REACT_APP_LOCAL_API_URL
}}
export const serverAPI=getAPIURL(environment)

export const formatValue = (n) => {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const activeStatus = ['Active', 'Inactive']

export const teamType = ['Dealership', 'App']       // Purchase Team type

export const emirate = ['ABU DHABI', 'other']       // Emirate

export const roles = ['MasterAdmin', 'Admin','User','Other']  // Roles

export const adSocial = ['web', 'fcb','ins', 'lnk', 'twt', 'goo', 'pin', 'tbr','dubicars','dc','dz','shop']  // Social Platforms