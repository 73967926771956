import React, { useEffect} from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig';
import { toast } from 'react-toastify';
import Layout from '../Layout'
import { useState } from 'react';
import { SearchIcon } from '@heroicons/react/solid'

const ListDealers = ({}) => { 
    const token =JSON.parse(localStorage.getItem("authuser")).access_token
    const headers = { 
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`,
    };
    const [ dealers, setDealers]  = useState([])
    const [ filteredDealers, setFilteredDealers]  = useState([])
    const [ search, setSearch]  = useState([])

    useEffect(() => {
        async function getDealer() {
            try {
              const res = await axios.get(`${serverAPI}/dealer`, { headers })
                .then(result => {
                setDealers(result.data);
                setFilteredDealers(result.data)
                }).catch((err, res) => {
                  // console.log(err)
                })
            } catch (err) {
              // console.log(err)
            }
          }
          getDealer();
    }, [])

    useEffect(() => {
        let filter = dealers.filter(
    person => {
      return (
        person
        .name
        .toLowerCase()
        .includes(search.toLowerCase()) ||
        person
        .number
        .includes(search)
      );
    }
  );
  console.log(filter)
setFilteredDealers(filter)
    }, [search])
    return (
        <> 
            <Layout>               
            <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div className='flex flex-row justify-between items-center pb-4'>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Dealers</h1>   
                        <div className="w-1/2">
                  <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                    <div className="w-full">
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative flex flex-row gap-x-4">
                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                          id="search"
                          name="search"
                          value={search}
                          onChange={(e)=>setSearch(e.target.value)}
                          className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm"
                          placeholder="Search Name or Number"
                          type="text"
                        />
                         <button
                        onClick={() =>setSearch("")}
                        className=" text-white group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
                      >
                        Clear
                      </button>
                      </div>

                    </div>
                  </div>
                </div>
                        <Link to={'/adddealer'}>
                        <button  type="submit" id="kt_sign_in_submit" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1 disabled:bg-gray-300 text-white">
                       + Add New
                        </button>   
                        </Link>        
                    </div>                             
                   {/* ---- Content Start ---- */}                      
                    <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">                                   
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Name</th>
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Search</th>
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Type</th>
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Notes</th>                                   
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Edit</th>
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Delete</th>
                                </tr>
                            </thead>

                            <tbody className="bg-white">                                   
                                {filteredDealers && [...filteredDealers].map((item, index) => (
                                    <tr key={index}>      
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {item.name}</div>
                                    </td>  
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {item.number}</div>
                                    </td>  
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {item.type}</div>
                                    </td>  
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {item.notes}</div>
                                    </td>                                           
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> 
                                        <Link to={`/editdealer?id=${item.id}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-blue-400"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                        </Link>
                                        </div>
                                    </td>    
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> 
                                        <Link to={`/removedealer?id=${item.id}`}>                                       
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-red-400"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                        </Link>                                       
                                        </div>
                                    </td>                                      
                                    </tr>
                                ))} 
                            </tbody>
                        </table>
                    </div>
                    {/* ---- Content End ---- */}
                </div>
            </div>   
            </Layout>     
        </>
    );
}
    
export default ListDealers