import React, {useEffect, useState} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../Layout'
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig';
import { activeStatus } from '../../utils/envConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const EditDealers= ({}) => { 
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();    
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [type, setType] = useState('');
    const [notes, setNotes] = useState('');
    const [memberId, setMemberId] = useState('');


    useEffect( async() => {
        if (searchParams.has("id")) {
            const memID = searchParams.get("id");           
            try{
                const token =JSON.parse(localStorage.getItem("authuser")).access_token
                const headers = { 
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`,
                };
                const res = await axios.get(`${serverAPI}/dealer/${memID}`,{headers})
                .then(user => {  
                    setMemberId(memID)
                    setName(user.data.name)
                    setNumber(user.data.number)      
                    setType(user.data.type)     
                    setNotes(user.data.notes)     
                }).catch((err) => {           
                        toast.error("Not available !")     
                }) 
            }catch(err){
                console.log(err)
                toast.error("Server error. Try Again !")
            }
        }
    }, []);

    const validate =(name)  => {
        return true
    }

    // Edit Purchase Team member Info in DB
    const editMember = async (e) => {   
        e.preventDefault()
        if(validate(name)){           
            const formData ={           
            name,
            type,
            notes
            }
            console.log(formData)
            try{
                const token =JSON.parse(localStorage.getItem("authuser")).access_token
                const headers = { 
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`,
                };
                const res = await axios.patch(`${serverAPI}/dealer/${memberId}`, formData,{headers})
                .then(user => {    
                    console.log(user)                
                    navigate('/listdealers')      
                }).catch((err) => {                    
                    toast.error("Error")               
                }) 
            }catch(err){
                console.log(err)
                toast.error("Server error. Try Again !")
            }
        }   
    } 

    return (
        <>    
        <Layout>    
        <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Edit Dealer</h1>              
                    </div>                             
                   {/* ---- Content Start ---- */}                      
                    <div className="inline-block min-w-full pt-2 pr-20 pl-20 pb-2 overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">               
                        <form name='member'
                        onSubmit={e => editMember(e)}>                                 
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th>                                       
                                    </th>  
                                    <th>
                                    </th>    
                                </tr>
                            </thead>
                            <tbody className="bg-white"> 
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Name </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <input className="bg-gray-200 w-1/2  border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="name" name="name" autoComplete="on" 
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Number </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">                                       
                                        <label 
                                         className="bg-gray-200 w-1/2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "                            
                                        >{number} </label>
                                        &nbsp; (ReadOnly)
                                    </td>
                                </tr>

                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Type </label>                                             
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"> 
                                        <select className="bg-gray-200 w-1/2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "
                                        onChange={e => setType(e.target.value)}
                                        name="status"
                                        value={type}
                                        >
                                            <option defaultValue>Select</option>                                                                                      
                                            <option value="Freelancer">Freelancer </option>
                                            <option value="Dealer">Dealer </option>
                                            <option value="OEM">OEM </option>
  
                                        </select>  &nbsp; &nbsp; &nbsp;    
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Notes </label>                                             
                                    </td>

                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <input className="bg-gray-200  w-1/2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="notes" name="notes" 
                                        value={notes}
                                        onChange={e => setNotes(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <button type="button" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                        onClick={() => navigate(-1)}
                                        >
                                        <span className="text-white font-sm">Go Back</span>
                                        </button>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">

                                            <button                                        
                                            type="submit" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                            >
                                            <span className="text-white font-sm">Edit Changes</span>
                                            </button>
                                      
                                    </td>
                                </tr>
                            </tbody>                           
                        </table>
                        </form>
                    </div>
                    {/* ---- Content End ---- */}
                </div>
            </div>    
        </Layout>
        </>
    );
}
    
export default EditDealers   