import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import _ from "lodash";
import axios from "axios";
import { serverAPI } from "../../utils/envConfig";
import Layout from "../Layout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

toast.configure();

const FilterDealers = ({}) => {
  const url = "/filter-dealers";

  const [array, setArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const excelFile = e.target.files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      let data = new Uint8Array(e.target.result);
      let workbook = XLSX.read(data, { type: "array" });

      // find the name of your sheet in the workbook first
      let firstSheet = Object.keys(workbook.Sheets)[0];
      let worksheet = workbook.Sheets[firstSheet];

      // convert to json format
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      submitData(excelFile.name, excelFile.type, jsonData);
    };
    setIsLoading(true);
    reader.readAsArrayBuffer(excelFile);
    e.target.value = null;
  };

  const submitData = async (fileName, fileType, jsonData) => {
    if (jsonData.length > 0) {
      try {
        const token = JSON.parse(localStorage.getItem("authuser")).access_token;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const res = await axios.post(
          `${serverAPI}/leadFilter/dealers`,
          {
            fileName: fileName,
            data: jsonData,
          },
          { headers }
        );
        if (res.data) {
          const apiData = res.data;
          let whatsappLinkColumnName = 'Whatsapp Link';
          let whatsappLinkColumnIndex = 0;
          if (apiData.length > 0) {
            const entries = Object.entries(apiData[0]);
            whatsappLinkColumnIndex = entries.findIndex(([key]) => key === whatsappLinkColumnName);
          }
          const ws = XLSX.utils.json_to_sheet(apiData);
          for (let i = 1; i < apiData.length + 1; i++) {
            ws[
              XLSX.utils.encode_cell({
                c: whatsappLinkColumnIndex,
                r: i,
              })
            ].l = { Target: apiData[i - 1][whatsappLinkColumnName] };
          }
          const wb = { Sheets: { dataDUApp: ws }, SheetNames: ["dataDUApp"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, fileName);
          setIsLoading(false);
          toast.success("File successfully filtered!");
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        toast.error("Server error. Try Again!");
      }
    } else {
      setIsLoading(false);
      toast.error(
        "No data in Excel file, please upload a valid excel file with the data in the first sheet"
      );
    }
  };

  return (
    <>
      <Layout>
        <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
          <div className="container mx-auto">
            <div className="grid grid-cols-3 gap-4">
              <div>
                <h1 className="mb-3 font-semibold text-lg lg:font-xl md:text-lg ">
                  Filter Dealers{" "}
                </h1>
              </div>
              <div className="invisible ..."></div>
            </div>
            <div className="inline-block min-w-full pt-2 pr-20 pl-20 pb-2 overflow-hidden align-middle bg-white border-b border-gray-200 shadow sm:rounded-lg">
              <div className="py-2 py-1">
                <span className="text-black font-sm">
                  {isLoading ? "Processing..." : "Import Excel"}
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="text-black font-sm">[.xlsx file]</span> &nbsp;
              </div>
              <div className="py-2 px-1">
                <input
                  className="upload-input w-full"
                  type="file"
                  name="file"
                  placeholder={null}
                  disabled={isLoading}
                  onChange={handleChange}
                />
              </div>
              <div className="py-2 py-1">
                <span className="text-gray-700 text-sm">
                  Filtering also normalizes numbers
                </span>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default FilterDealers;
