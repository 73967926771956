import React, {  useState ,useEffect,useContext } from 'react'
import { TeamContext } from '../../contexts/TeamContext';
import { teamType, emirate } from '../../utils/envConfig';
import Layout from '../Layout';
import moment from 'moment';
import axios from 'axios'
import { serverAPI } from '../../utils/envConfig'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const ListSchedule = ({}) => { 
    const { purchaseTeam } = useContext(TeamContext);
    let currentDate = moment();
    let today = currentDate.format("D-MMM-YYYY");
    let tomorrow = currentDate.add(1, 'days').format("D-MMM-YYYY")
    let sunday = moment().day(0).format("D-MMM-YYYY")
    let check = (sunday===today)
    let weekStart = currentDate.clone().startOf('isoWeek');
    const [purchaseFilter, setPurchaseFilter] = useState( purchaseTeam ? purchaseTeam : []);
    const [purchaseType, setPurchaseType] = useState(teamType[0] ? teamType[0] : '');
    const [place, setPlace] = useState(emirate[0] ? emirate[0] : '');
    const [weekDays, setWeekDays] = useState([])
    const [weekName, setWeekName] = useState([])

    const [checkedState, setCheckedState] = useState(Array.from({length: purchaseFilter.filter((s => s.purchaseType.includes(purchaseType) && s.status)).length},()=> Array.from({length: 7}, () => false)));
    const [sch, setSch] = useState({});
    const [loading, setLoading] = useState(true);
    const token =JSON.parse(localStorage.getItem("authuser")).access_token
    const headers = { 
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`,
    };
    const transpose = (matrix) => {
        let [row] = matrix
        return row.map((value, column) => matrix.map(row => row[column]))
    }
    useEffect(() => {
        const getSch = async()  => { 
            try {
                const res = await axios.get(`${serverAPI}/purchaseSchedule`,{headers})
                .then(schedule => {  
                   setSch(schedule.data)  
                   setLoading(false)
                }).catch((err, res) => {
                   //  console.log(err)
                })
               } catch(err) {
                   // console.log(err)
               }
        }
        getSch();
        getCurrentWeek();
    }, [])

    useEffect(() => {
        let obj = []
        const setCheck = async()=>{
            let filterUser=purchaseFilter.filter((s => s.purchaseType.includes(purchaseType) && s.status))
            let row=filterUser.length
            let col=weekDays.length
            if(purchaseType==teamType[0]){
                for (let i=0;i<col;i++){
                    obj[i]=sch.filter(s=>(s.emirate===place) && (s.purchaseType===purchaseType) && moment(new Date(s.schDate)).format('DD-YYYY-MM')===moment(new Date(weekDays[i])).format('DD-YYYY-MM')).map(z=>z.availability)
                } 
            }
            else{
                for (let i=0;i<col;i++){
                    obj[i]=sch.filter(s=>(s.purchaseType===purchaseType) && moment(new Date(s.schDate)).format('DD-YYYY-MM')===moment(new Date(weekDays[i])).format('DD-YYYY-MM')).map(z=>z.availability)
                } 
            }
            if(obj[0] && obj[0].length>0){
                let resetChecked=transpose(obj)
                setCheckedState(resetChecked)
                setLoading(false)
            }
            else if(obj[0] && obj[0].length==0){
                toast.error("No schedule for this week")
            }
            else{             
            }
        }
        setCheck();
    }, [loading])

    const selectedFilter = async(e) => {    
        let ptvalue = e.target.value;        
        setPurchaseType(ptvalue)
        setLoading(true)
    };

    const selectedEmirate = (e) => {    
        let ptvalue = e.target.value;        
        setPlace(ptvalue)
        setLoading(true)
    };
   
    function getCurrentWeek() {              
        if(check)    {                
            weekStart = tomorrow
        }
        var days = [];
        let names=[]
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, 'days').format("yyyy-MM-DD"));
        }            
        setWeekDays(days)
        for (let i = 0; i <= 6; i++) {
            names.push(moment(weekStart).add(i, 'days').format("dddd"));
        } 
        setWeekName(names)
    }

    const handleOnChange = (x,y,day) => e => { 
        const newcheckedState  =checkedState.map((row,i )=>
        (i === x) ? row.map((item,index) =>(index === y) ? !item : item) : row.map((item,index) =>(index === y) ? item : item)
        )                  
        setCheckedState(newcheckedState)
    }

    return (
        <>               
        <Layout>   
            {loading&&  
                <div className="w-full pt-5 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                    <div className="container mx-auto"> 
                        <div className="flex justify-center items-center">
                            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            }   
            {!loading&&            
            <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg "> List Team Member Shifts</h1>   
                    </div>                             
                    {/* ---- Content Start ---- */}                                
                    <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">  
                        <form name='purchase'>
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th className="px-6 py-3 text-xs font-medium-bold leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Purchase Type:  &nbsp;&nbsp;
                                        <select className="bg-[#F5F8FA] border-gray-200 border rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "
                                        onChange={e => selectedFilter(e)}
                                        name="purchaseType"
                                        value={purchaseType}                                       
                                        >
                                            <option value={teamType[0]}>{teamType[0]} </option>
                                            <option value={teamType[1]}>{teamType[1]} </option>
                                        </select>    
                                    </th> 
                                    {purchaseType===teamType[0] &&
                                    <th className="px-6 py-3 text-xs font-medium-bold leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Emirate:  &nbsp;&nbsp;
                                        <select className="bg-[#F5F8FA] border-gray-200 border rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "
                                        onChange={e => selectedEmirate(e)}
                                        name="emirate"
                                        value={place}                                       
                                        >
                                            <option value={emirate[0]}>{emirate[0]} </option>
                                            <option value={emirate[1]}>{emirate[1]} </option>
                                        </select>    
                                    </th>  
                                    }
                                    <th className="px-6 py-3 text-xs font-medium-bold leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        {today}
                                    </th>                                                                                      
                                </tr>
                            </thead>
                            <tbody > 
                                <tr>   
                                    <td className="px-6 py-6 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500">                                           
                                        </div>
                                    </td>                                                                                                 
                                </tr>
                            </tbody>
                        </table>
                        </form>
                        
                        <form name='schedule'>                                            
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                                        Users</th>                                   
                                    {weekDays && [...weekDays]
                                    .map(day => (
                                    <th key={day}
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase  bg-gray-50">
                                        {day}</th>
                                    ))} 
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase  bg-gray-50">
                                         </th> 
                                </tr>
                                <tr>
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                       </th>                                   
                                    {weekName && [...weekName]
                                    .map(day => (
                                    <th key={day}
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        {day}</th>
                                    ))} 
                                    <th
                                        className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                         </th> 
                                </tr>
                            </thead>

                            <tbody className="bg-white">                                   
                                {purchaseFilter && [...purchaseFilter]
                                .filter((s => s.purchaseType.includes(purchaseType) && s.status))
                                .map((item,i) => (
                                    <tr key={i}>   
                                    <td className="px-6 py-6 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> {item.name}</div>
                                    </td>  
                                    {weekDays && [...weekDays]
                                    .map((day, index) => (
                                    <React.Fragment key={day}>                                 
                                    <td className="px-6 py-6 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500">
                                            <div>
                                                <div className="form-check">
                                                <input 
                                                id={`custom-checkbox-${index}`}
                                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                                                type="checkbox" 
                                                value={day}
                                                name={day}
                                                checked={checkedState[i][index]}
                                                disabled="disabled"                                                
                                                onChange={ handleOnChange(i,index,day)}
                                                /> 
                                                </div>                                                   
                                            </div>                                      
                                        </div>
                                        <div className="text-sm leading-5 text-gray-500"> 
                                        </div>
                                    </td> 
                                    </React.Fragment>                                           
                                    ))} 
                                    <td className="px-6 py-6 whitespace-no-wrap border-b border-gray-200">
                                    </td>                                                                           
                                    </tr>
                                ))} 
                            </tbody>
                        </table> 
                        </form>
                    </div>               
                </div>
            </div>  
            }
        </Layout>      
        </>
    );
}
    
export default ListSchedule
    