import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from 'moment';
import Layout from '../Layout';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const downloadCSV=async(e,fileName)=>{
    e.preventDefault()
    // Get signed url from s3 
    try {
        const uploadData=JSON.stringify(
            {                
                "fileName": fileName            
            }
        );
        const token =JSON.parse(localStorage.getItem("authuser")).access_token
        const headers = { 
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token}`,
        };
        // Upload to s3 using signed url
        const resp = await axios.post(`${serverAPI}/filter/csvDealDownload`,uploadData,{ headers });
        const url =resp.data.signedRequest
        if(url){
            try {
              
                // const myHeaders = new Headers({
                //     "Content-Disposition": "attachment"
                //     }
                // );
                // const file=await axios.get(url,myHeaders);
                window.location.href=url

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        }
    } catch (err) {
        // Handle Error Here
        console.error(err);
    }
    
}
const ListDeal = ({}) => {   
    const [listDeal, setListDeal] = useState([]);     

    useEffect(() => {
        getDealList()
        return () => {
            setListDeal([]); 
        };        
    }, []);

    const getDealList = async () => {
        const token =JSON.parse(localStorage.getItem("authuser")).access_token
        const headers = { 
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token}`,
        };
        const res = await axios.get(`${serverAPI}/filter/csvDealList`,{headers})
            .then(files => {  
                const valueToRemove = 'FIL_DEAL/'
                const data = files.data.filter(item => item !== valueToRemove) 
                data.sort((a, b) => b.localeCompare(a)) 
                setListDeal(data);                  
            }).catch((err, res) => {
                console.log(err)
            })        
    }
    
    return (
        <>   
        <Layout>    
            <div className="w-full px-4 py-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto mt-8"> 
                    <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">                                   
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th
                                        className="px-6 py-3 text-xs font-extrabold leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        Download Dealership Files</th>                                  
                                </tr>
                            </thead>
                            <tbody className="bg-white">                                   
                                {listDeal && [...listDeal].map((item, index) => (
                                    <tr key={index}>   
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-500"> <button className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1" onClick ={ (e) => {downloadCSV(e,item)}}>{item}</button></div>
                                    </td>                      
                                    </tr>

                                ))} 
                            </tbody>
                        </table>
                    </div>
                
                </div>
            </div>    
        </Layout>
        </>
    );
}
    
export default ListDeal
    