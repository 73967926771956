import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from '../Layout'
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig';
import { activeStatus, teamType } from '../../utils/envConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const AddSalesTeam = ({}) => { 
    let navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [whatsupdub, setWhatsupdub] = useState('');
    const [whatsupfb, setWhatsupfb] = useState('');
    const [status, setStatus] = useState(true);

    const validate =(name,email,phone,whatsupfb)  => {
        if(name.length < 3)  {
            toast.error("Enter valid name")
            return false
        }

        if(email.length < 5)  {
            toast.error("Enter valid email")
            return false
        }

        if(whatsupfb !== phone)  {
            toast.error("Whatsapp link for Facebook should be same as phone number")
            return false
        }
     
        if(phone.length !== 9)  {
          toast.error("Enter valid phone number")
          return false
        }
        else  {     
          var phoneRGEX = /[0-9]/;
          var phoneResult = phoneRGEX.test(phone);
           
          if(phoneResult) {
            return true
          } else{
            toast.error("Enter valid phone number")
            return false
          }
        }      
    }

    // Add Purchase Team member Info in DB
    const addMember = async (e) => {   
        e.preventDefault()
        if(validate(name,email,phone,whatsupfb)){           
            let phoneno = `+971${phone}`  
            // let whatsupdubadd = `https://bit.ly/${whatsupdub}`  
            let whatsupfbadd = `https://wa.me/971${whatsupfb}`  
            const formData ={           
            name,
            email,
            phone: phoneno,
            whatsupdub: phoneno,
            whatsupfb: whatsupfbadd,
            status            
            }
            // console.log(formData)
            try{
                const token =JSON.parse(localStorage.getItem("authuser")).access_token
                const headers = { 
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`,
                };
                const res = await axios.post(`${serverAPI}/salesTeam`, formData,{headers})
                .then(user => {                    
                    navigate('/listsalesteam')      
                }).catch((err) => {
                    if (err.response.data.message === 'EMAIL EXIST') {                  // DONT CHANGE MESSAGE, THE SAME REFERED FROM BACKEND
                        toast.error("Sales Member not added. Email already exist !")
                    } else {
                        toast.error("Sales Member not added !")
                    }                    
                }) 
            }catch(err){
                console.log(err)
                toast.error("Server error. Try Again !")
            }
        }   
    } 

    return (
        <>    
        <Layout>    
            <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Add Sales Team Member</h1>              
                    </div>                             
                   {/* ---- Content Start ---- */}                              
                    <div className="inline-block min-w-full pt-2 pr-20 pl-20 pb-2 overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">               
                        <form name='member'
                        onSubmit={e => addMember(e)}>                                 
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th>                                       
                                    </th>  
                                    <th>
                                    </th>    
                                </tr>
                            </thead>
                            <tbody className="bg-white"> 
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Name </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <input className="bg-gray-200  border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="name" name="name" autoComplete="on" 
                                        required 
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Email </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <input className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="email" name="email" autoComplete="on" 
                                        required 
                                        placeholder="abc@abc.com"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Phone </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label 
                                        className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "                            
                                        >+971</label> &nbsp;
                                        <input 
                                        className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="number" name="phone" autoComplete="on" 
                                        placeholder="501234567"
                                        maxLength="9"
                                        required 
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                        />
                                    </td>
                                </tr>                                
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Whatsapp Link for Facebook</label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                        <label 
                                        className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "                            
                                        >https://wa.me/971</label> &nbsp;
                                        <input className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="number" name="whatsupfb" autoComplete="on" 
                                        required 
                                        placeholder="501234567"
                                        value={whatsupfb}
                                        onChange={e => setWhatsupfb(e.target.value)}
                                        />                                        
                                    </td>
                                </tr>
                                {/* <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Whatsapp Link for Dubizzle</label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                                        <label 
                                        className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "                            
                                        >https://bit.ly/</label> &nbsp;
                                        <input className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="text" name="whatsupdub" autoComplete="on" 
                                        placeholder="3dqCF6D"
                                        value={whatsupdub}
                                        onChange={e => setWhatsupdub(e.target.value)}
                                        />                                        
                                    </td>
                                </tr> */}
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Status </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label 
                                         className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "                            
                                        >{activeStatus[0]} </label>
                                        &nbsp; (ReadOnly)
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <button type="submit" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                        >
                                        <span className="text-white font-sm">Add</span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>                           
                        </table>
                        </form>
                    </div>
                    {/* ---- Content End ---- */}
                </div>
            </div>    
        </Layout>
        </>
    );
}
    
export default AddSalesTeam    