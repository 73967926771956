import { Navigate, Outlet } from "react-router-dom";
import React, { useState } from 'react'

const useAuth = () => {  
    const [authuser, setAuthuser] = useState('')
    let user = { loggedIn: false }

    if (localStorage.getItem("authuser") === null) {
        return false
    } else {
        if(!(localStorage.getItem(authuser))){  
            user = { loggedIn: true }  
            return user && user.loggedIn
        }
        else {
            return user && user.loggedIn
        }
    }  
}

const ProtectedRoutes = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Navigate to="/" />
}

export default ProtectedRoutes;