import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { serverAPI } from '../utils/envConfig'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const Login = ({}) => {
    let navigate = useNavigate();
    const [authuser, setAuthuser] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

  	const submitLogin = async (e) => {
        e.preventDefault()
        const userData = {
            email,
            password
        }   
        try{
            const res = await axios.post(`${serverAPI}/auth/login`, userData)
            .then(user => {  
                localStorage.setItem('authuser', JSON.stringify(user.data));   
                navigate('/home')      
            }).catch((err) => {
                console.log(err)
                toast.error("Invalid Login")
            }) 
        }catch(err){
            console.log(err)
        }
    }

    const handleReset= (e, email)=>{
        e.preventDefault()          
        if(!email){
            toast.error("Enter Email")
        }
        else {
            navigate({
                pathname: '/resetpassword',
                search: `?email=${email}`
            });           
        }
    }

    return (	
        <>
        <div className="flex flex-col  bg-no-repeat bg-cover bg-hero  h-screen justify-center items-center overflow-hidden" >
        <div className="flex flex-center flex-col p-10 md:pb-10 lg:pb-10 justify-center items-center">
            <a href="/" className="m-6 sm:mb-2 align-middle">
            <img alt="Logo" src="./img/logo-demo7.svg" className="h-12 md:h-16 lg:h-16"/>
            </a>
            <div className="bg-white rounded shadow-sm p-8 md:p-5 lg:p-8 mx-auto block">
                <form className="form sm:w-full leading-normal" noValidate="novalidate" id="kt_sign_in_form" action="#" > 
                    <div className="text-center mb-2 lg:mb-6 md:mb-4">
                        <h1 className="mb-3 font-semibold text-lg lg:font-xl md:text-lg">Marketing Dashboard</h1>
                    </div>
                    <div className="mb-2 lg:mb-6 md:mb-4">
                        <label className="text-xsmall text-bold leading-normal">Email </label>
                        <input className="bg-[#F5F8FA] border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                        type="email" name="email" autoComplete="on" 
                        required 
                        onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-2 lg:mb-6 md:mb-4">                       
                        <label className="text-xsmall  text-bold leading-normal">Password </label>
                        <input className="bg-[#F5F8FA] border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1" 
                        type="password" name="password" autoComplete="off"
                        required
                        onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="mb-2 lg:mb-6 md:mb-4">
                        <button type="submit" id="kt_sign_in_submit" className="group relative w-full flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
                        onClick={e=>submitLogin(e)}
                        >
                        <span className="text-white font-sm">Sign in</span>
                        </button>                       
                    </div>  
                    <div className="text-center mb-2 lg:mb-6 md:mb-4">
                        <Link to={''} onClick={(e) => handleReset(e, email)}>
                        <label className="text-xsmall underline text-bold mb-0">Reset Password</label>
                        </Link>
                    </div>                  
                </form>
            </div>
        </div>
        <div className="flex flex-center  p-10">
            <div className="flex items-center font-bold">
                <a href="https://albacars.ae" className="text-[#A1A5B7] text-xsmall px-2">About</a>
                <a href="mailto:marketing@albacars.ae" className="text-[#A1A5B7] text-xsmall">Contact</a>
            </div>
        </div>
        </div>
        </>
    )
}

export default Login