import { useEffect, useContext, useState } from "react";
import { useAdFormContext } from "../../../contexts/AdFormContext";
import axios from 'axios';
import { serverAPI } from '../../../utils/envConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

export default function Final() {
  const { adData, setAdData } = useAdFormContext();
  const [msg, setMsg ] = useState('Ad for car has been created !');
 
  useEffect( async() => {   
    // console.log('context data: ',adData)

    const validate =()  => {
      if(adData.carstockno===''|| adData.carname==='' || adData.caryear==='' ||
      adData.carprice===''||adData.carodometer===''||
      adData.carcylinder===''||adData.carwheel===''||adData.carspec===''||adData.description==='')  {
        setMsg('Ad creation failed!')
        toast.error("Please enter all the mandatory fields and try again", {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          });
        return false
    }

    else {
        return true
    }

    }

    // Add 'Ad Generation' in DB    
    if(validate()){           
      const formData ={       
      carstockno: adData.carstockno,    
      carname: adData.carname,    
      caryear: adData.caryear,
      cartitle: adData.cartitle,
      carprice: adData.carprice,
      caremi: adData.caremi,
      carodometer: adData.carodometer,
      carwarranty: adData.carwarranty,
      carservice: adData.carservice,
      carcylinder: adData.carcylinder,
      carwheel: adData.carwheel,
      carspec: adData.carspec,
      offers: adData.offers,
      description: adData.description,
      options: adData.options,
      cashbuyers: adData.cashbuyers,
      financebuyers: adData.financebuyers,
      booking: adData.booking,
      addexpenses: adData.addexpenses,
      sellyourcar: adData.sellyourcar,
      carwebsite: adData.carwebsite,
      carwebsitepin: adData.carwebsitepin,
      carwebsitetumblr: adData.carwebsitetumblr,
      carlocationgoogle: adData.carlocationgoogle,
      carshowroom: adData.carshowroom,
      carhashinsta: adData.carhashinsta,
      carhashlnkd: adData.carhashlnkd,
      carhashtwitter: adData.carhashtwitter,
      contactteams: adData.contactteams,
      createdate: adData.createdate,
      createby: adData.createby,                
      }
      // console.log(formData)
      try{
        const token =JSON.parse(localStorage.getItem("authuser")).access_token
        const headers = { 
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`,
        };
        const res = await axios.post(`${serverAPI}/adGen`, formData,{headers})
        .then(user => {     
          // console.log(user)              
        }).catch((err) => {
          console.log(err)        
        }) 
      }catch(err){
        console.log(err)
        toast.error("Server error. Try Again !")
      }  
    }
  }, [])

  return (
    <div className="container md:mt-10">
      <div className="flex flex-col items-center">
        <div className="wrapper">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>

        <div className="mt-3 text-xl font-semibold">
          {msg}
        </div>    
        <a className="mt-10" href="/adformlisttoday">
          <button 
          id="kt_sign_in_submit" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"      
          >
          <span className="text-white font-sm">
            Close
          </span>            
          </button>
        </a>
      </div>
    </div>
  );
}