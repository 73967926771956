import React, {useEffect, useState} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../Layout'
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig';
import { activeStatus, teamType } from '../../utils/envConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const EditTeam = ({}) => { 
    let navigate = useNavigate();
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [purchaseType, setPurchaseType] = useState('');
    const [status, setStatus] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();    
    const [member, setMember] = useState({});
    const [memberId, setMemberId] = useState('');
    const [buttonDisable, setButtonDisable] = useState(true);

    useEffect( async() => {
        if (searchParams.has("id")) {
            const memID = searchParams.get("id");           
            try{
                const token =JSON.parse(localStorage.getItem("authuser")).access_token
                const headers = { 
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`,
                };
                const res = await axios.get(`${serverAPI}/purchaseTeam/validMember/${memID}`,{headers})
                .then(user => {                
                    setMember(user.data)
                    setMemberId(user.data._id)
                    setName(user.data.name)
                    setEmail(user.data.email)
                    setPhone((user.data.phone).slice(4))
                    setStatus(user.data.status)
                    setPurchaseType(user.data.purchaseType)
                }).catch((err) => {           
                    if (err.response.data.message === 'ASSIGNED') {                     // DONT CHANGE MESSAGE, THE SAME REFERED FROM BACKEND
                        setButtonDisable(false)
                        toast.error("Purchase Member has been assigned shifts. Remove from shifts first !")
                    } else {
                        toast.error("Purchase Member not available !")     
                    }                      
                }) 
            }catch(err){
                console.log(err)
                toast.error("Server error. Try Again !")
            }
        }
    }, []);

    const validate =(name,phone,status)  => {
        if(name.length < 3)  {
            toast.error("Enter valid name")
            return false
        }

        if(status === 'Select' || purchaseType === '')  {
            toast.error("Select the Status")
            return false
        }

        if(phone.length !== 9)  {
          toast.error("Enter valid phone number")
          return false
        }
        else  {     
          var phoneRGEX = /[0-9]/;
          var phoneResult = phoneRGEX.test(phone);
           
          if(phoneResult) {
            return true
          } else{
            toast.error("Enter valid phone number")
            return false
          }
        }      
    }

    // Edit Purchase Team member Info in DB
    const editMember = async (e) => {   
        e.preventDefault()
        if(validate(name,phone,status)){           
            let phoneno = `+971${phone}`  
            const formData ={           
            name,
            phone: phoneno,
            status,
            }

            try{
                const token =JSON.parse(localStorage.getItem("authuser")).access_token
                const headers = { 
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`,
                };
                const res = await axios.patch(`${serverAPI}/purchaseTeam/${memberId}`, formData,{headers})
                .then(user => {    
                    // console.log(user)                
                    navigate('/purchaseteam')      
                }).catch((err) => {                    
                    toast.error("Purchase Member not modified with changes !")               
                }) 
            }catch(err){
                console.log(err)
                toast.error("Server error. Try Again !")
            }
        }   
    } 

    return (
        <>     
        <Layout>               
            <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Edit Purchase Team Member</h1>              
                    </div>                             
                   {/* ---- Content Start ---- */}                               
                    <div className="inline-block min-w-full pt-2 pr-20 pl-20 pb-2 overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">               
                        <form name='member'
                        onSubmit={e => editMember(e)}>                                 
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th>                                       
                                    </th>  
                                    <th>
                                    </th>    
                                </tr>
                            </thead>
                            <tbody className="bg-white"> 
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Name </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <input className="bg-gray-200  border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="name" name="name" autoComplete="on" 
                                        required 
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Email </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">                                       
                                        <label 
                                         className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "                            
                                        >{email} </label>
                                        &nbsp; (ReadOnly)
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Phone </label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label 
                                        className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "                            
                                        >+971</label> &nbsp;
                                        <input 
                                        className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 " 
                                        type="number" name="phone" autoComplete="on" 
                                        placeholder="501234567"
                                        maxLength="9"
                                        required 
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Purchase Type</label>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">                                       
                                        <label 
                                         className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "                            
                                        >{purchaseType} </label>
                                        &nbsp; (ReadOnly)
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <label className="text-small text-bold leading-normal">Status </label>                                             
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"> 
                                        <select className="bg-gray-200 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "
                                        onChange={e => setStatus(e.target.value)}
                                        name="status"
                                        value={status}
                                        >
                                            <option defaultValue>Select</option>                                                                                      
                                            <option value={true} >{activeStatus[0]} </option>
                                            <option value={false} >{activeStatus[1]} </option>
                                        </select>  &nbsp; &nbsp; &nbsp;    
                                    </td>
                                </tr>
                                <tr>                                 
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        <button type="button" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                        onClick={() => navigate(-1)}
                                        >
                                        <span className="text-white font-sm">Go Back</span>
                                        </button>
                                    </td>
                                    <td className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        {buttonDisable && buttonDisable
                                        ?
                                            <button                                        
                                            type="submit" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                            >
                                            <span className="text-white font-sm">Edit Changes</span>
                                            </button>
                                        :
                                            <button                                        
                                            type="button" id="kt_sign_in_submit" className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                       
                                            disabled
                                            >
                                            <span className="text-white font-sm">Edit Changes - Disabled</span>
                                            </button>
                                        }                                        
                                    </td>
                                </tr>
                            </tbody>                           
                        </table>
                        </form>
                    </div>
                    {/* ---- Content End ---- */}
                </div>
            </div>    
        </Layout>
        </>
    );
}
    
export default EditTeam    