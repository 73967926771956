import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import ClipboardCopy from './ClipboardCopy';
import DownloadText from './DownloadText';

const AdTwitter = ({postData,emiyear,refId}) => { 
  let emi=''
  if(emiyear!==' through bank finance'){
    emi = emiyear.replace(/\D/g,'')
    emi=`(${(emi)+' Years'})⁣`
}

    let navigate = useNavigate();

    // Twitter Ad Post Template
    const txt = 
`${postData.carname} | ${postData.caryear} | ${postData.cartitle}

-AED ${postData.carprice} | AED ${postData.caremi} Per Month ${emi} | 0% Downpayment

Visit Website :  ${postData.carwebsitepin}

${postData.carhashtwitter}

${refId}`

  return (  
    <>    
        <div className="w-0 flex-1 flex items-center">                           
            <ClipboardCopy copyText={txt} />
            <DownloadText downloadText={txt} fileName='Twitter.txt' />
        </div>
        <textarea
            id="offers"
            name="offers"
            value={txt}
            readOnly 
            rows={10}
            className="shadow-sm pl-2 pt-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
        />
    </>
  );
}

export default AdTwitter