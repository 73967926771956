import React from "react";
import Navbar from './AdminNavbar'
import Topbar from './Topbar'

export default function Layout({children}){
  return (    
      <>
        <Topbar />
        <div className="flex h-full min-h-screen">    
        <Navbar />
        {children} 
        </div>    
      </>   
  );
}