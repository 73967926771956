import React, {  useState ,useEffect,useContext } from 'react'
import { TeamContext } from '../../contexts/TeamContext';
import { teamType, emirate } from '../../utils/envConfig';
import Layout from '../Layout';
import moment from 'moment';
import axios from 'axios'
import { serverAPI } from '../../utils/envConfig'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const ListSchedule = ({}) => { 
    const { purchaseTeam } = useContext(TeamContext);   
    let currentDate = moment();
    let today = currentDate.format("D-MMM-YYYY");
    let tomorrow = currentDate.add(1, 'days').format("D-MMM-YYYY")
    let sunday = moment().day(0).format("D-MMM-YYYY")
    let check = (sunday===today)
    let weekStart = currentDate.clone().startOf('isoWeek');
    const [purchaseFilter, setPurchaseFilter] = useState( purchaseTeam ? purchaseTeam : []);
    const [purchaseType, setPurchaseType] = useState(teamType[0] ? teamType[0] : '');
    const [place, setPlace] = useState(emirate[0] ? emirate[0] : '');
    const [weekDays, setWeekDays] = useState([])
    const [weekName, setWeekName] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [checkedState, setCheckedState] = useState(Array.from({length: purchaseFilter.filter((s => s.purchaseType.includes(purchaseType) && s.status)).length},()=> Array.from({length: 7}, () => false)));
    const [btnLoading, setBtnLoading] = useState(false);
    const [sch, setSch] = useState({});
    const [loading, setLoading] = useState(true);
    const [disable, setDisable]=useState(false);
    const token =JSON.parse(localStorage.getItem("authuser")).access_token
    const headers = { 
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`,
    }

// console.log(weekDays .map((day, index)=>(moment(today).isSameOrAfter(moment(day)))))
    const transpose = (matrix) => {
        let [row] = matrix
        return row.map((value, column) => matrix.map(row => row[column]))
    }

    useEffect(() => {
        const getSch = async()  => { 
            try {
                const res = await axios.get(`${serverAPI}/purchaseSchedule`,{headers})
                .then(schedule => {  
                   setSch(schedule.data)  
                   setLoading(false)
                }).catch((err, res) => {
                   //  console.log(err)
                })
            } 
            catch(err) {
            // console.log(err)
            }
        }
        getSch();
        getCurrentWeek();
    }, [])

    useEffect(() => {
        let obj = []
        const setCheck = async()=>{
            let filterUser=purchaseFilter.filter((s => s.purchaseType.includes(purchaseType) && s.status))
            let row=filterUser.length
            let col=weekDays.length
            if(purchaseType==teamType[0]){
                for (let i=0;i<col;i++){
                    obj[i]=sch.filter(s=>(s.emirate===place) && (s.purchaseType===purchaseType) && moment(new Date(s.schDate)).format('DD-YYYY-MM')===moment(new Date(weekDays[i])).format('DD-YYYY-MM')).map(z=>z.availability)
                } 
            }
            else{
                for (let i=0;i<col;i++){
                    obj[i]=sch.filter(s=>(s.purchaseType===purchaseType) && moment(new Date(s.schDate)).format('DD-YYYY-MM')===moment(new Date(weekDays[i])).format('DD-YYYY-MM')).map(z=>z.availability)
                } 
            }
            if(obj[0]&& obj[0].length>0){
                let resetChecked=transpose(obj)
                setCheckedState(resetChecked)
                setLoading(false)
            }
            else if(obj[0] && obj[0].length==0){
                toast.error("No schedule for this week")
            }
            else{             
            }
        }
        setCheck();
    }, [loading])

    const selectedFilter = async(e) => {    
        let ptvalue = e.target.value;        
        setPurchaseType(ptvalue)
        setLoading(true)
        setDisable(false)
    }
    const selectedEmirate = (e) => {    
        let ptvalue = e.target.value;        
        setPlace(ptvalue)
        setDisable(false)
        setLoading(true)
    }
    function getCurrentWeek() {              
        if(check)    {                
            weekStart = tomorrow
        }
        var days = [];
        let names=[]
        for (let i = 0; i <= 6; i++) {
          days.push(moment(weekStart).add(i, 'days').format("yyyy-MM-DD"));
        }            
        setWeekDays(days)
        for (let i = 0; i <= 6; i++) {
            names.push(moment(weekStart).add(i, 'days').format("dddd"));
          } 
        setWeekName(names)
    }
    const handleOnChange = (x,y,day) => e => { 
        const newcheckedState  =checkedState.map((row,i )=>
        (i === x) ? row.map((item,index) =>(index === y) ? !item : item) : row.map((item,index) =>(index === y) ? item : item)
        )                
        setCheckedState(newcheckedState)
    }
    const handleModal= (e)=>{
        e.preventDefault()
        setShowModal(true)
    }
    const submitSch = async (e) => {       
        e.preventDefault()
        setBtnLoading(true)
        let scheduleData = [];
        let filterUser=purchaseFilter.filter((s => s.purchaseType.includes(purchaseType) && s.status))
        let row=filterUser.length
        let col=weekDays.length
        const validate =(checkedState)  => {
            let arr=[]
            let vaildArray=[]
            for(let i=0;i<col;i++){
                arr.push([])
                for(let j=0;j<row;j++){
                    arr[i].push(checkedState[j][i])
                }
                if(!arr[i].includes(true)){
                    vaildArray.push(false)
       
                }
            }
            if(vaildArray.includes(false)){
                    setBtnLoading(false)
                    setShowModal(false)
                  toast.error(`Please assign atleast one user for each day`)
                return false
            }
            return true
    
        }
  
        for (let i = 0; i < row; i++) {
            for (let j = 0; j <  col; j++) {
                let oneRow = {               
                    memberId: filterUser[i]._id,
                    purchaseType:  purchaseType,
                    schDate: new Date(weekDays[j]),
                    availability:checkedState[i][j],
                    emirate:place
    
                }; 
                scheduleData.push(oneRow)    
            }
          }
        try{
            if(validate(checkedState)){
                setDisable(true)
                const res = await axios.patch(`${serverAPI}/purchaseSchedule/${purchaseType}/${place}`, scheduleData,{headers})
                .then(data => { 
                    axios.post(`${serverAPI}/purchaseSchedule`, scheduleData,{headers})
                    .then(result=> { 
                        setBtnLoading(false)
                        setShowModal(false)
                    toast.success("Updated")     
                    })   
                }).catch((err) => {
                    setBtnLoading(false)
                    setShowModal(false)
                    toast.error("Error")
                }) 
            }
        }catch(err){
            // console.log(err)
        }
    }

    return (
        <>               
         <Layout>   
            {loading&&  
            <div className="w-full pt-5 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto"> 
                    <div className="flex justify-center items-center">
                        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            }   
            {!loading&&            
            <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
                <div className="container mx-auto">  
                    <div>
                        <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg "> Edit Team Member Shifts</h1>   
                    </div>                             
                    {/* ---- Content Start ---- */}                                
                    <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">  
                        <form name='purchase'>
                            <table className="min-w-full">
                                <thead>
                                    <tr>
                                        <th className="px-6 py-3 text-xs font-medium-bold leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Purchase Type:  &nbsp;&nbsp;
                                            <select className="bg-[#F5F8FA] border-gray-200 border rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "
                                            onChange={e => selectedFilter(e)}
                                            name="purchaseType"
                                            value={purchaseType}                                       
                                            >
                                                <option value={teamType[0]}>{teamType[0]} </option>
                                                <option value={teamType[1]}>{teamType[1]} </option>
                                            </select>    
                                        </th> 
                                        {purchaseType===teamType[0] &&
                                        <th className="px-6 py-3 text-xs font-medium-bold leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Emirate:  &nbsp;&nbsp;
                                            <select className="bg-[#F5F8FA] border-gray-200 border rounded py-2 px-4 text-gray-700 leading-tight outline-none focus:outline-none text-tiny focus:border-red-500 focus:ring-red-500 focus:ring-1 "
                                            onChange={e => selectedEmirate(e)}
                                            name="emirate"
                                            value={place}                                       
                                            >
                                                <option value={emirate[0]}>{emirate[0]} </option>
                                                <option value={emirate[1]}>{emirate[1]} </option>
                                            </select>    
                                        </th>  
                                        }
                                        <th className="px-6 py-3 text-xs font-medium-bold leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            {today}
                                        </th>                                                                                      
                                    </tr>
                                </thead>
                                <tbody > 
                                    <tr>   
                                        <td className="px-6 py-6 whitespace-no-wrap border-b border-gray-200">
                                            <div className="text-sm leading-5 text-gray-500">                                           
                                            </div>
                                        </td>                                                                                                 
                                    </tr>
                                </tbody>
                            </table>
                        </form>                      
                        <form name='schedule'>                      
                            <table className="min-w-full">
                                <thead>
                                    <tr>
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                                            Users</th>                                   
                                        {weekDays && [...weekDays]
                                        .map(day => (
                                        <th key={day}
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase  bg-gray-50">
                                            {day}</th>
                                        ))} 
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase  bg-gray-50">
                                            </th> 
                                    </tr>
                                    <tr>
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                        </th>                                   
                                        {weekName && [...weekName]
                                        .map(day => (
                                        <th key={day}
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            {day}</th>
                                        ))} 
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            </th> 
                                    </tr>
                                </thead>

                                <tbody className="bg-white">                                   
                                    {purchaseFilter && [...purchaseFilter]
                                    .filter((s => s.purchaseType.includes(purchaseType) && s.status))
                                    .map((item,i) => (
                                        <tr key={i}>   
                                        <td className="px-6 py-6 whitespace-no-wrap border-b border-gray-200">
                                            <div className="text-sm leading-5 text-gray-500"> {item.name}</div>
                                        </td>  
                                        {weekDays && [...weekDays]
                            .map((day, index) => (
                                <React.Fragment key={day}>                                 
                                <td className="px-6 py-6 whitespace-no-wrap border-b border-gray-200">
                                    <div className="text-sm leading-5 text-gray-500">
                                        <div>
                                            <div className="form-check">
                                            <input 
                                            id={`custom-checkbox-${index}`}
                                            disabled={ (moment(today).isSameOrAfter(moment(day)))}
                                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                                            type="checkbox" 
                                            value={day}
                                            name={day}
                                            checked={checkedState[i][index]}
                                            onChange={ handleOnChange(i,index,day)}
                                            /> 
                                            </div>                                                   
                                        </div>
                                    
                                    </div>
                                    <div className="text-sm leading-5 text-gray-500"> 

                                    </div>
                                </td> 
                                </React.Fragment>                                           
                            ))} 
                            <td className="px-6 py-6 whitespace-no-wrap border-b border-gray-200">

                            </td>                                                                           
                                        </tr>
                                    ))} 

                                </tbody>
                                <tfoot className='justify-right'>
                                <button disabled={disable } type="submit"                                            
                                    className="group relative flex justify-center py-2 px-2 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1  disabled:bg-red-200"
                                    onClick={(e) => handleModal(e)}
                                    >
                                    <span className="text-white font-sm">Submit</span>
                                </button>           
                                    </tfoot>
                            </table>
                        </form>
                    </div>              
                </div>
            </div>             
            }
            {showModal ? (
            <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="relative p-8 flex-auto">
                        <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                        {purchaseType===teamType[0] &&
                        `${purchaseType} - ${place} ` 
                        }
                        {purchaseType===teamType[1] &&
                        ` ${purchaseType}`
                        }
                        <br/>
                        </p>
                        Confirm shift schedule for this week
                    
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => setShowModal(false)}
                        >
                            No
                        </button>
                        <button
                            className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={(e) => submitSch(e)}
                        >
                        Yes
                        {btnLoading && (
                                <svg role="status" className="inline mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            )}
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
            ) : null} 
       </Layout>      
        </>
    );
}
    
export default ListSchedule
    