import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { serverAPI } from '../../utils/envConfig'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const ExportDealDU = ({}) => {   
    const [dataDUDeal, setDataDUDeal] = useState([]);
    const [isDataDownloading, setIsDataDownloading] = useState(true);
    // const [enableExport, setEnableExport] = useState(false);
    
    //Set Filename
    let dt = new Date();
    dt.setDate(dt.getDate() - 1)
    const fileYesterday = moment(dt).format('YYYY_MM_DD');
    
    // Yesterday date as filename
    const fileNameDUDeal = `FIL_DEAL_${fileYesterday}`   // Dubbizle- Dealership

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    useEffect( async() => {
        setIsDataDownloading(true);
        const token =JSON.parse(localStorage.getItem("authuser")).access_token
        const headers = { 
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token}`,
        };
        const res = await axios.get(`${serverAPI}/filter/dudeal`,{headers})
            .then(users => {      
                let newArray = [];
                users.data.map((item) => {
                    // Extracting only required columns
                    let obj = { 
                        'TITLE': item.open, 
                        'EXPECTED PRICE (AED)': item.aed,
                        'YEAR': item.year,
                        'MILEAGE (Kms)': item.kilometers,
                        'COLOR': item.color,
                        'WARRANTY': item.warranty,
                        'TRANSMISSION': item.transmission,
                        'BODY TYPE': item.bodyType,
                        'CYLINDERS (Engine size)': item.cylinders,
                        'OPTIONS': item.extras,
                        'MOBILE ( Contact Number)': item.phone,
                        'BRAND': item.brand,
                        'MODEL': item.model,
                        'AD LINK (URL)': item.url,
                        'DATE':item.date,
                        'Short Link': item.shortLink,
                        'UUID': item.uuid,
                        'DESCRIPTION': item.description,
                        'POSTED BY': item.postedBy,
                        'EMIRATE': item.emirate,                       
                        'SMS SENT (Purchase Team Member)': item.smsSent,
                        'Purchase Agent Number': item.agentNumber,
                        'SOLD ?': item.sold,
                        'Reference Number': item.refNumber,
                        'UNIQUE': item.unique,
                        'Whatsapp Link': item.whatsappLink,
                        'LEAD STATUS': item.leadStatus,
                        'LEAD SOURCE': item.leadSource,
                    };                    
                    newArray.push(obj);
                    setDataDUDeal(newArray);
                    setIsDataDownloading(false);
                });
            }).catch((err, res) => {
                console.log(err);
                setIsDataDownloading(false);
            })
    }, [])
    
    const exportToCSVDUDeal = (apiData, fileName) => {       
        if(apiData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(apiData);
            for (let i = 1; i < apiData.length + 1; i++) {
                ws[XLSX.utils.encode_cell({
                  c: 25,
                  r: i
                })].l = { Target:apiData[i-1]["Whatsapp Link"] };
                ws[XLSX.utils.encode_cell({
                    c: 13,
                    r: i
                  })].l = { Target:apiData[i-1]["AD LINK (URL)"] };
                  ws[XLSX.utils.encode_cell({
                    c: 15,
                    r: i
                  })].l = { Target:apiData[i-1]["Short Link"] };
            }

            const wb = { Sheets: { dataDUDeal: ws }, SheetNames: ["dataDUDeal"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const dataDUDeal = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(dataDUDeal, fileName + fileExtension);
            toast.success("Downloading Dealership data !")
        } else {
            console.log('No data')
            toast.error("Dealership data not yet available. Try Again !")
        }        
    };  

    // // Enable/Disable "Export to Excel" button
    // let starthours = 11
    // let endhours = 23
    // let curhours = dt.getHours();

    // // Enable - Current hour match start hour
    // const enableLive = async() => {
    // console.log('Enable Export') 
    // setEnableExport(true)
    // } 
   
    // let DiffEnable = starthours - curhours

    // if(DiffEnable === 0) {    
    //     console.log('Enable trigger')                                             
    //     setTimeout(enableLive, DiffEnable); // trigger once
    // }  

    // // Disable - Current hour match start hour
    // const disableLive = async() => {
    //     console.log('Disable Export') 
    //     setEnableExport(false)
    // } 
    
    // let DiffDisable = endhours - curhours

    // if(DiffDisable === 0) { 
    //     console.log('Disable trigger')                                                  
    //     setTimeout(disableLive, DiffDisable); // trigger once
    // }  

    return (
        <>   
            <form className="form sm:w-full leading-normal mt-10" noValidate="novalidate" id="kt_sign_in_form" action="#" > 
                <span className="text-gray font-sm font-bold">Dealership </span>  (Filename: {fileNameDUDeal}.csv)   
                {/* {enableExport && enableExport
                ?                 
                    <button 
                    type="submit" id="kt_sign_in_submit"                   
                    className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                                       
                    onClick={(e) => exportToCSVDUDeal(dataDUDeal, fileNameDUDeal)}
                    >
                    <span className="text-white font-sm">Export to Excel</span>
                    </button>
                :                  
                    <button 
                    disabled 
                    type="submit" id="kt_sign_in_submit"                   
                    className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"                                       
                    onClick={(e) => exportToCSVDUDeal(dataDUDeal, fileNameDUDeal)}
                    >
                    <span className="text-white font-sm">Export to Excel</span>
                    </button>
                } */}
                <button 
                type="submit" id="kt_sign_in_submit"                   
                className="group relative flex justify-center py-2 px-4 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1 disabled:bg-red-900"                                       
                disabled={isDataDownloading}
                onClick={(e) => exportToCSVDUDeal(dataDUDeal, fileNameDUDeal)}
                >
                <span className="text-white font-sm">{isDataDownloading ? "Downloading..." : "Export to Excel"}</span>
                </button>
            </form>   
        </>
    );
}
    
export default ExportDealDU
    