import React,{useEffect, useState} from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios'
import { serverAPI ,teamType,emirate} from '../utils/envConfig'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const Unsubscribe = ({}) => { 
    let { email} = useParams();
    const [msg,setMsg] = useState('You have successfully unsubscribed.')
    useEffect( async() => {
        try {
            const headers = { 
                'Content-Type': 'application/json',
            };
         const res = await axios.get(`${serverAPI}/newsletter/unsubscribe/?email=${email}`,{headers})
         .then(result => {  
            setMsg("You have successfully unsubscribed.")
         }).catch((err, res) => {
            setMsg("Something went wrong..")
         })
        } catch(err) {
            console.log(err)
        }
    }, [])
    return (
        <>
        <div className="flex flex-col  bg-no-repeat bg-cover bg-hero  h-screen justify-center items-center overflow-hidden" >
        <div className="flex flex-center flex-col p-10 md:pb-10 lg:pb-10 justify-center items-center">
            <div className="bg-white rounded shadow-sm p-8 md:p-5 lg:p-8 mx-auto block">
            {msg}
            </div>
        </div>

        </div>
        </>
    );
}

export default Unsubscribe
