import { useEffect, useState } from "react";
import { useAdFormContext } from "../../../contexts/AdFormContext";
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

export default function Contact() {
  const { adData, setAdData } = useAdFormContext();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdData({ ...adData, [name]: value });
  };
  useEffect( () => {   
    // console.log('context data: ',adData)
    const validate =()  => {
      if(adData.carstockno===''|| adData.carname==='' || adData.caryear==='' ||
      adData.carprice===''||adData.carodometer===''|| adData.carwarranty===''||
      adData.carcylinder===''||adData.carwheel===''||adData.carspec===''||adData.description==='')  {
        toast.error('Missing data', {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          });
        return false
    }
    else if(adData.contactteams===''){
      toast.error('Contacts not set, please go back and validate', {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        });
      return false
    }
    else {
        return true
    }
    }
    validate();
  }, [])

  return (  
    <div className="space-y-5"> 
    <form method="POST">
      {/* <div className="bg-white shadow px-4 py-5 mb-2 sm:rounded-lg sm:p-6">      
        <div className="md:grid md:grid-cols-6 md:gap-4">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Contact Details: </h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-4">
            
            <div className="grid grid-cols-6 gap-6">     
              <div className="col-span-6 sm:col-span-6">
                         
                {
                  adData["contactteams"] && 
                  adData["contactteams"].map((item, index) => (    
                    <div>
                      <label htmlFor="contactteam" className="block text-sm font-medium text-gray-700">
                      {item.name}
                      </label> 
                      <label htmlFor="contactteam" className="block text-sm font-medium text-gray-700">
                      {item.phone}
                      </label> 
                      <label htmlFor="contactteam" className="block text-sm font-medium text-gray-700">
                      {item.whatsupdub}
                      </label> 
                      <label htmlFor="contactteam" className="block text-sm font-medium text-gray-700">
                      {item.whatsupfb}
                      </label>   
                      <br />                    
                    </div>
                  ))
                }          
              </div>
            </div>
          </div>
        </div>
      </div>    */}

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-6 md:gap-4">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Created Information: </h3>
          <p className="mt-0 mb-2 text-sm text-gray-500">(Readonly)</p>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-4">
          <div className="grid grid-cols-6 gap-6">     
            <div className="col-span-6 sm:col-span-6">
              <label htmlFor="createdate" className="block text-sm font-medium text-gray-700">
                Created Date
              </label>
              <label htmlFor="createdate" className="block text-sm font-medium text-gray-700">
                {moment(adData["creacreatedateteby"]).format("D-MMM-YYYY")} 
              </label>  
            </div>

            <div className="col-span-6 sm:col-span-6">
              <label htmlFor="createby" className="block text-sm font-medium text-gray-700">
                Created By
              </label>
              <label htmlFor="createby" className="block text-sm font-medium text-gray-700">
                {adData["createby"]} 
              </label>              
            </div>
          </div>
        </div>
        </div>
      </div>
    </form>   
    </div> 
  );
}