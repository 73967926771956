import { useState } from "react";
import Layout from "../Layout";
import AdFormAddStep from "./AdFormAddStep";
import AdFormAddControl from "./AdFormAddControl";
import { UseContextProvider } from "../../contexts/AdFormContext";

import General from "./formsteps/General";
import Contact from "./formsteps/Contact";
import Final from "./formsteps/Final";

function AdFormAdd() {
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    "General",
    "Contact",
    "Complete",
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <General />;
      case 2:
        return <Contact />;
      case 3:
        return <Final />;
      default:
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  return (
    <>    
    <Layout>   
        <div className="w-full pt-3 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
            <div className="container mx-auto">  
                <div>
                    <h1 className="mb-2 font-semibold text-lg lg:font-xl md:text-lg ">Ad Generation - Create New Ad</h1>              
                </div>     

                {/* ---- Content Start ---- */}               
                {/* AdFormAddStep */}                
                <AdFormAddStep steps={steps} currentStep={currentStep} />
                <div className="my-5 p-5 ">
                    <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
                </div>         

                {/* navigation button */}
                {currentStep !== steps.length && (
                    <AdFormAddControl
                    handleClick={handleClick}
                    currentStep={currentStep}
                    steps={steps}
                    />
                )}
                {/* ---- Content End ---- */}

            </div>
        </div>
    </Layout>
    </>
  );
}

export default AdFormAdd;