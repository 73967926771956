import { createContext, useContext, useState, useEffect } from "react";
import moment from "moment";

const AdFormContext = createContext({ adData: "", setAdData: null });

export function useAdFormContext() {
  const { adData, setAdData } = useContext(AdFormContext);
  return { adData, setAdData };
}

export const rotateArray = function (nums, k) {
  for (let i = 0; i < k; i++) {
    nums.unshift(nums.pop());
  }
  return nums;
};
// Context Provider to Multi-Forms
export function UseContextProvider({ children }) {
  // Created Date
  let today = moment();
  // let today = currentDate.format("D-MMM-YYYY");

  // Created By
  const createdBy = JSON.parse(localStorage.getItem("authuser")).email;

  // Default Values
  const intWarranty = `Under Warranty`;
  const intWebsite = `www.albacars.ae`;
  const intLocationgoogle = `https://goo.gl/maps/XrGnafTmhQQuRqMV9`;
  const intShowroom = `Sunday to Saturday (10:00 AM - 10:00 PM)`;
  const intHashinsta = `#Luxurycarsdubai #albacars #cardealerUAE #carsforsale #luxurycars #carswithoutlimits #newcar #carshopping #cars #dealership #carscene #autosales #carshow #usedcarsforsale #carsofinsta #carsdaily #carspot #cardealership #auto #usedcars #carlifestyle #car #automotive #carsofinstagram`;
  const intHashlnkd = `#albacars #luxurycars #usedcarsshowroom`;
  const intHashtwitter = `#albacars #usedcars #luxurycars #dubaicars #offers #deals`;
  const intCashbuyers = `Please provide: 
1- Emirates ID 
2- Driving License`;
  const intFinancebuyers = `Required Bank finance documents are as follows:
—
Employed:
1- Salary Certificate
2- 3 month bank statement (stamped)
3- Passport & Visa copies)
4- Emirates ID copy
( Note: Please contact us if you have received only one or no salaries and work for a listed company)
—
Self Employed:
1- Trade License
2- MOA
3- Passport copies of all partners
4- Passport and visa copies of applicant
5- Emirates ID
6- 3 month personal bank statement
7- 3 month company bank statement
—
Companies:
1- Trade License
2- MOA
3- Passport copies of all partners
4- 3 month company bank statement`;
  const intBooking = `To start the application we require a deposit of AED 5,000 through one of the following methods :
-
1- Credit/Debit card - to be refunded in cash once vehicle is registered.
—
2- Cash - to be refunded as Cash once vehicle is registered.
—
3- Cheque - Not to be cashed - to be returned once vehicle is registered.
—
Applicable Terms & Conditions to be presented upon the time of booking.`;
  const intAddexpenses = `1- Registration: AED 945
2- Insurance: TBC
3- VAT 5%`;
  const intSellyourcar = `Simply fill out the form by clicking the link below :
—
https://albacars.ae/sell-used-car-dubai/
—
We pay cash and pay for Bank early settlements.`;
  // const intCaroptions=
  // `-Satellite Navigation
  // -Rear Camera/360 Camera/Parking Sensors
  // -Cruise Control/Adaptive Cruise Control
  // -Sunroof/Panoramic Sunroof/Dual Sunroof
  // -Lane Assist
  // -Collision Warning
  // -Blind Spot Indicator
  // -Heads Up Display
  // -Leather Seats/Half Leather Seats
  // -Driving Modes
  // -Rear Entertainment
  // -Keyless Entry
  // -Keyless Start
  // -Remote Start
  // -Number of seats:
  // -Apple Car Play
  // -Android Auto
  // -Electric Tailgate
  // -Cooling Seats
  // -Ambient Lighting
  // -Rear AC
  // -Third Row AC
  // -Cooling Box
  // -Electric Rear Folding Seats
  // -Wireless Charger
  // -Size of Alloy:
  // -ISOFIX
  // -Sound System:
  // Additional Options:`
  const intCaroptions = `Coming Soon`;
  // set Ad Form Varaibles
  // carwebsitetumblr is used to store the car model
  // carhashlnkd is used to store car trim => for dubizzle,dubicars title only
  const [adData, setAdData] = useState({
    carstockno: "",
    carname: "",
    caryear: "",
    cartitle: "",
    carprice: "",
    caremi: "",
    carodometer: "",
    carwarranty: intWarranty,
    carservice: "",
    carcylinder: "",
    carwheel: "",
    carspec: "",
    offers: "",
    description: "",
    options: intCaroptions,
    cashbuyers: intCashbuyers,
    financebuyers: intFinancebuyers,
    booking: intBooking,
    addexpenses: intAddexpenses,
    sellyourcar: intSellyourcar,
    carwebsite: intWebsite,
    carwebsitepin: "",
    carwebsitetumblr: "",
    carlocationgoogle: intLocationgoogle,
    carshowroom: intShowroom,
    carhashinsta: intHashinsta,
    carhashlnkd: "",
    carhashtwitter: intHashtwitter,
    contactteams: [],
    createdate: today || "",
    createby: createdBy || "",
  });

  return (
    <AdFormContext.Provider value={{ adData, setAdData }}>
      {children}
    </AdFormContext.Provider>
  );
}
